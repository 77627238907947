export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-project-checklist/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-lite-project-checklist/SET_LOADING_FALSE';
export const GET_ALL_LOOKUPS_SUCCESS = 'cbre-pjm-principal-lite-project-checklist/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'cbre-pjm-principal-lite-project-checklist/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_LOOKUPS_START = 'cbre-pjm-principal-lite-project-checklist/GET_ALL_LOOKUPS_START';
export const GET_FINANCIAL_OVERVIEW_SUCCESS =
  'cbre-pjm-principal-lite-project-checklist/GET_FINANCIAL_OVERVIEW_SUCCESS';
export const GET_FINANCIAL_OVERVIEW_FAILURE =
  'cbre-pjm-principal-lite-project-checklist/GET_FINANCIAL_OVERVIEW_FAILURE';
export const RESET_FINANCIAL_OVERVIEW_MESSAGE =
  'cbre-pjm-principal-lite-project-checklist/RESET_FINANCIAL_OVERVIEW_MESSAGE';
export const MODULE = 'cbre-pjm-principal-lite-project-checklist';
