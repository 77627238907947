/* eslint-disable */
import { Action, IManageReferenceReduxState } from '../type';
import {
  EDIT_REFERENCE_SUCCESS,
  CREATE_REFERENCE_SUCCESS,
  CREATE_REFERENCE_START,
  CREATE_REFERENCE_FAILURE,
  RESET,
  SET_DEFAULT_VALUES,
  RESET_API_MSG,
  EDIT_REFERENCE_FAILURE,
  EDIT_REFERENCE_START,
} from './constants';

const INITIAL_STATE: IManageReferenceReduxState = {
  loading: false,
  fetchProjectDetails: true,
  apiMessage: '',
  apiMessageStatus: '',
};

export default (state = INITIAL_STATE, action: Action<string, string>) => {
  switch (action.type) {
    case EDIT_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiMessage: action.payload,
        apiMessageStatus: 'success',
      };
    case EDIT_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        apiMessage: action.payload,
        apiMessageStatus: 'failure',
      };
    case EDIT_REFERENCE_START:
      return {
        ...state,
        loading: true,
        apiMessage: '',
        apiMessageStatus: '',
      };
    case CREATE_REFERENCE_START:
      return {
        ...state,
        loading: true,
        apiMessage: '',
        apiMessageStatus: '',
      };
    case CREATE_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        apiMessage: action.payload,
        apiMessageStatus: 'success',
      };
    case CREATE_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        apiMessage: action.payload,
        apiMessageStatus: 'failure',
      };
    case SET_DEFAULT_VALUES:
      return {
        ...state,
        defaultValues: action.payload,
      };
    case RESET_API_MSG:
      return {
        ...state,
        apiMessage: '',
        apiMessageStatus: '',
      };
    case RESET:
      return {
        ...state,
        loading: false,
        apiMessage: '',
        apiMessageStatus: '',
      };

    default:
      return state;
  }
};
