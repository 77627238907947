import { getMessage } from 'util/utilityMethods';
import {
  GET_CLIENT_START,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  GET_CLIENT_RESET,
  GET_CUSTOM_FIELDS,
  GET_CUSTOM_FIELDS_SUCCESS,
  GET_CUSTOM_FIELDS_PUBLISH_SUCCESS,
  GET_CUSTOM_FIELDS_FAILURE,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CLOSE_DELETE_USER_TOAST,
  UPDATE_USER_ACCESS_STATUS_START,
  UPDATE_USER_ACCESS_STATUS_SUCCESS,
  UPDATE_USER_ACCESS_STATUS_FAILURE,
  CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST,
  GET_KAHUA_FIELD_LIST_START,
  GET_KAHUA_FIELD_LIST_SUCCESS,
  GET_KAHUA_FIELD_LIST_FAILURE,
  GET_KAHUA_FIELD_LIST_RESET,
  SET_SECTION_HEADER,
  UPDATE_SECTION_HEADER_SUCCESS,
  UPDATE_SECTION_HEADER_FAILURE,
  SAVE_DROPDOWN_LIST_START,
  SAVE_DROPDOWN_LIST_END,
  SET_CDF_REQUIRED,
} from './constants';

const INITIAL_STATE = {
  alertSuccess: false,
  alertMessage: '',
  clientDetails: {},
  showDeleteToaster: false,
  saveRenderCount: 0,
  error: false,
  errorCode: '',
  loading: true,
  userAccessData: {},
  statusError: false,
  statusAlert: '',
  pageSize: 10,
  totalRecords: 0,
  pageIndex: 0,
  deletedUserCount: 0,
  customFieldDetails: [],
  KahuaList: [],
  selectedSection: {},
  sectionHeaderAlertSuccess: false,
  isPublish: false,
  isCdfRequired: false,
};

// eslint-disable-next-line import/no-anonymous-default-export,complexity
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_CUSTOM_FIELDS:
      return {
        ...state,
        loading: true,
        error: '',
        ...action.payload,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientDetails: action.payload,
        totalRecords: action.payload.totalRecords,
        pageIndex: action.payload.pageIndex,
      };
    case GET_CUSTOM_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        customFieldDetails: action.payload,
        totalRecords: action.payload.length,
      };
    case GET_CUSTOM_FIELDS_PUBLISH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        isPublish: action.payload,
      };
    case UPDATE_SECTION_HEADER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        sectionHeaderAlertSuccess: true,
        customFieldDetails: action.payload,
        totalRecords: action.payload.length,
      };
    case UPDATE_SECTION_HEADER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        sectionHeaderAlertSuccess: false,
        errorCode: action.payload,
      };

    case GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_CUSTOM_FIELDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        alertSuccess: 'failure',
        errorCode: action.payload,
      };
    case GET_CLIENT_RESET:
      return {
        ...state,
        loading: false,
        clientDetails: {},
      };
    case GET_KAHUA_FIELD_LIST_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_KAHUA_FIELD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        KahuaList: action.payload.recordsList,
      };
    case GET_KAHUA_FIELD_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_KAHUA_FIELD_LIST_RESET:
      return {
        ...state,
        loading: false,
        KahuaList: [],
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        alertSuccess: 'success',
        alertMessage: getMessage({ id: 'msg.user.deleleSuccess' }),
        showDeleteToaster: true,
        deletedUserCount: action.payload,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        alertSuccess: 'failure',
        error: true,
        errorCode: action.payload,
      };
    case DELETE_USER_START:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CLOSE_DELETE_USER_TOAST:
      return {
        ...state,
        alertSuccess: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    case UPDATE_USER_ACCESS_STATUS_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case SET_SECTION_HEADER:
      return {
        ...state,
        ...action.payload,
        error: '',
      };
    case UPDATE_USER_ACCESS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusAlert: 'success',
        userAccessData: action.payload,
        saveRenderCount: state.saveRenderCount + 1,
      };
    case UPDATE_USER_ACCESS_STATUS_FAILURE:
      return {
        ...state,
        statusError: true,
        statusAlert: 'failure',
      };
    case CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST:
      return {
        ...state,
        statusAlert: 'close',
      };
    case SAVE_DROPDOWN_LIST_START:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case SAVE_DROPDOWN_LIST_END:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case SET_CDF_REQUIRED:
      return {
        ...state,
        isCdfRequired: action.payload,
      };
    default:
      return state;
  }
};
