/* eslint-disable react/jsx-filename-extension */
import AppLocale from '../lngProvider';
import { FormattedMessage, injectIntl } from 'react-intl';

const enLocale = AppLocale.en_US.messages;
// eslint-disable-next-line react/jsx-props-no-spreading
const InjectMassage = (props) => {
  /* eslint-disable react/prop-types */
  /* eslint-disable react/jsx-props-no-spreading */
  const { id } = props;
  /* eslint-disable react/jsx-props-no-spreading */
  const { defaultMessage = enLocale[id] } = props;
  return <FormattedMessage {...props} defaultMessage={defaultMessage} />;
};
export default injectIntl(InjectMassage, {
  withRef: false,
});
