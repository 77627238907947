export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-budget-management/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-lite-budget-management/SET_LOADING_FALSE';
export const SET_SUB_ACTIVITY = 'cbre-pjm-principal-lite-budget-management/SET_SUB_ACTIVITY';
export const GET_BUDGET_DETAILS_START = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_DETAILS_START';
export const GET_BUDGET_DETAILS_RESET = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_DETAILS_RESET';
export const GET_BUDGET_DETAILS_SUCCESS = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_DETAILS_SUCCESS';
export const GET_BUDGET_DETAILS_FAILURE = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_DETAILS_FAILURE';
export const GET_BUDGET_LIST_FAILURE = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_LIST_FAILURE';
export const GET_BUDGET_LIST_START = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_LIST_START';
export const GET_BUDGET_LIST_RESET = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_LIST_RESET';
export const GET_BUDGET_LIST_SUCCESS = 'cbre-pjm-principal-lite-budget-management/GET_BUDGET_LIST_SUCCESS';
export const UPDATE_TOTALS = 'cbre-pjm-principal-lite-budget-management/UPDATE_TOTALS';
export const BUDGET_DELETE_START = 'cbre-pjm-principal-lite-budget-management/BUDGET_DELETE_START';
export const BUDGET_DELETE_SUCCESS = 'cbre-pjm-principal-lite-budget-management/BUDGET_DELETE_SUCCESS';
export const BUDGET_DELETE_FAILED = 'cbre-pjm-principal-lite-budget-management/BUDGET_DELETE_FAILED';
export const BUDGET_DELETE_RESET = 'cbre-pjm-principal-lite-budget-management/BUDGET_DELETE_RESET';

export const subNavList = [
  {
    id: 0,
    name: 'budget',
    label: `cbre-pjm-principal-lite-project-cost-plan.heading.variance`,
  },
  { id: 1, name: 'variance', label: 'Variance' },
  { id: 2, name: 'costplan', label: 'J&A Cost Breakdown' }, // J&A Cost Breakdown
];

export const idSubTabNameMapping = {
  0: 'budget',
  1: 'variance',
  2: 'costplan',
};

// TODO: need to match the DB Schema -> After it is finalized
export const defaultBudgetManagementValues = {
  defaultCostStructureId: 0,
  description: '',
  costTypeId: 507,
  unitId: 28,
  quantity: 0,
  unitPrice: 0,
  totalCost: 0,
  isBillable: false,
  margin: 15.0,
  markup: 0.0,
  totalSell: 0,
  activityName: '',
};

// TODO: Need to match schema
export const newBudgetPackage = {
  activity: '',
  // budgetHdrId: 72,
  projectWorkFlowStatusId: 0.0,
  date: '',
  purchaseRequestId: '',
  description: '',
  budgetLineItem: [
    {
      activity: '',
      description: '',
      quantity: 0,
      unitId: 0.0,
      unitPrice: 0.0,
      totalCost: 0.0,
      isSelected: null,
      costTypeId: 0,
      isBillable: false,
      margin: 0.0,
      totalSell: 0.0,
      markup: 0.0,
      status: '',
      amount: 0.0,
    },
  ],
};
