/* eslint-disable */
import {
  GET_ALL_CLIENTS_EXISTS_FAILURE,
  GET_ALL_CLIENTS_START,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  REQUEST_ACCESS_START,
  REQUEST_ACCESS_SUCCESS,
  REQUEST_ACCESS_EXISTS_FAILURE,
  REQUEST_ACCESS_FAILURE,
  CLOSE_REQUEST_ACCESS_TOAST,
  OPEN_REQUEST_ACCESS_DIALOG,
  CLOSE_REQUEST_ACCESS_DIALOG,
} from './constants';

import { getMessage } from 'util/utilityMethods';

const INITIAL_STATE = {
  allClientList: {},
  loading: false,
  error: false,
  errorCode: '',
  open: false,
  clientId: '',
  clientName: '',
  notes: '',
};

const ClientInfo = (state = INITIAL_STATE, action) => {
  // Get clients
  switch (action.type) {
    case OPEN_REQUEST_ACCESS_DIALOG:
      return {
        ...state,
        open: true,
      };
    case CLOSE_REQUEST_ACCESS_DIALOG:
      return {
        ...state,
        open: false,
      };
    case GET_ALL_CLIENTS_START: {
      return {
        ...state,
        loading: false,
        allClientList: action.payload,
      };
    }
    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allClientList: action.payload,
      };
    case GET_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_ALL_CLIENTS_EXISTS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    }
    // Request Access
    case REQUEST_ACCESS_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case REQUEST_ACCESS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        alert: 'success',
        alertMessage: getMessage({ id: 'msg.client.approval' }),
        open: false,
      };
    case REQUEST_ACCESS_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        alert: 'information',
        alertMessage: getMessage({ id: 'msg.client.approvalPending' }),
        open: false,
      };
    case REQUEST_ACCESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };

    case CLOSE_REQUEST_ACCESS_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
      };

    default:
      return state;
  }
};

export default ClientInfo;
