/* eslint-disable */
import moment from 'moment';
import {
  TRACKER_GET_ALL_LOOKUPS_SUCCESS,
  TRACKER_GET_ALL_LOOKUPS_FAILURE,
  TRACKER_ADD_NEW_PROJECT_START,
  TRACKER_ADD_NEW_PROJECT_SUCCESS,
  TRACKER_ADD_NEW_PROJECT_FAILURE,
  TRACKER_ADD_NEW_PROJECT_EXISTS_FAILURE,
  TRACKER_CLOSE_NEW_PROJECT_TOAST,
  TRACKER_RESET_NEW_PROJECT_STATUS,
  TRACKER_RESET_NEW_PROJECT_ERROR,
  TRACKER_FILE_UPLOAD_NEW_PROJECT_START,
  TRACKER_FILE_UPLOAD_NEW_PROJECT_SUCCESS,
  TRACKER_FILE_UPLOAD_NEW_PROJECT_ERROR,
  TRACKER_GET_PROJECT_BY_ID_START,
  TRACKER_GET_PROJECT_BY_ID_SUCCESS,
  TRACKER_SET_INITIALS,
} from './constants';

const defaultProjectTrackerProject = {
  referringRegion: '',
  originatingGroup: '',
  originatingClient: '',
  otherClientName: '',
  originatingDivision: '',
  otherDivisionName: '',
  projectName: '',
  projectType: '',
  projectStatus: '',
  kahuaProjectNumber: '',
  kahuaId: '',
  nonKahuaProjectNumber: '',

  pjmAdvisory: '',
  pjmEnterprise: '',
  pjmLocalTech: '',
  nonPjm: '',
  turnerTownsend: '',
  currency: 80,
  estimatedGrossRevenue: '',
  actualGrossRevenue: '',
  estimatedGrossMargin: '',
  actualGrossMargin: '',
  estimatedStartDate: null,
  actualStartDate: null,
  estimatedFinishDate: null,
  actualFinishDate: null,
  comments: '',

  receivingPersonnel: '',
  referralPersonnel: '',
  'upload-pif-form': '',
  pifCheckbox: false,

  receivingRegion: '',
  receivingSegment: '',
  receivingClient: '',
  receivingCustomClient: '',
  receivingDivision: '',
  receivingCustomDivision: '',
  isReferralDivided: false,
};

const INITIAL_STATE = {
  planCriteriaLookups: [],
  allLegacy: {},
  simpleLookups: [],
  fetchLookupsError: false,
  clientsList: [],
  usersList: [],

  defaultProjectTrackerProject,
  projectTrackerProject: defaultProjectTrackerProject,
  attachedFileNames: [''],
  attachedFileList: [{ originalFileName: '' }],
  attachedFileIds: [],
  loading: false,
  fetchLookupsStatus: '',
  createProjectStatus: '',
  createProjectError: '',
  messageCode: '',
  obj: {},
  alert: '',
  mode: '',
  kahuaManaged: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRACKER_GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetchLookupsStatus: 'success',
      };
    case TRACKER_GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        fetchLookupsStatus: 'error',
      };
    case TRACKER_SET_INITIALS:
      return {
        ...state,
        mode: 'add',
        ...action.payload,
      };
    case TRACKER_FILE_UPLOAD_NEW_PROJECT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case TRACKER_ADD_NEW_PROJECT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case TRACKER_ADD_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        createProjectStatus: 'success',
        alert: 'success',
        createProjectError: '',
        messageCode: '',
      };
    case TRACKER_ADD_NEW_PROJECT_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        createProjectStatus: 'duplicate',
        createProjectError: true,
        ...action.payload,
      };
    case TRACKER_ADD_NEW_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        createProjectStatus: 'error',
        createProjectError: true,
        ...action.payload,
      };
    case TRACKER_CLOSE_NEW_PROJECT_TOAST:
      return {
        ...state,
        ...action.payload,
        createProjectStatus: '',
        mode: '',
      };
    case TRACKER_RESET_NEW_PROJECT_STATUS:
      return {
        ...state,
        createProjectStatus: '',
        mode: '',
        attachedFileList: [{ originalFileName: '' }],
        attachedFileIds: [],
      };
    case TRACKER_RESET_NEW_PROJECT_ERROR:
      return {
        ...state,
        createProjectError: '',
        messageCode: '',
        createProjectStatus: '',
      };
    case TRACKER_GET_PROJECT_BY_ID_START:
      return {
        ...state,
        loading: true,
      };
    case TRACKER_GET_PROJECT_BY_ID_SUCCESS:
      const projectResponse = action.payload;
      try {
        const projectInfo = {
          referringRegion:
            (projectResponse.projectTrackerHdr.referringRegion &&
              projectResponse.projectTrackerHdr.referringRegion.regionId) ||
            '',
          originatingGroup:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.orignatingGroupId) || '',
          originatingClient:
            projectResponse.client && projectResponse.client.id
              ? { clientId: projectResponse.client.id, clientName: projectResponse.client.name }
              : '',
          otherClientName: (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.customClient) || '',
          originatingDivision:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.divisionId) || '',
          otherDivisionName:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.customDivision) || '',
          projectName: projectResponse.projectTitle || '',
          projectType: projectResponse.projectTypeId || '',
          projectStatus: projectResponse.projectStatusId || '',
          kahuaProjectNumber: projectResponse.kahuaNumber || '',
          createdBy: projectResponse.createdByUser.userId || '',
          kahuaId: projectResponse.kahuaId || '',
          nonKahuaProjectNumber:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.projectNumber) || '', // (projectResponse.client && projectResponse.client.id) || '',
          isReferralDivided: projectResponse?.projectTrackerHdr?.isReferralDivided,
          pjmAdvisory: projectResponse?.projectTrackerHdr?.isReferralDivided
            ? projectResponse.projectTrackerHdr.advisoryPercent
            : 0,
          pjmEnterprise: projectResponse?.projectTrackerHdr?.isReferralDivided
            ? projectResponse.projectTrackerHdr.enterprisePercent
            : 0,
          pjmLocalTech: projectResponse?.projectTrackerHdr?.isReferralDivided
            ? projectResponse.projectTrackerHdr.localTechPercent
            : 0,
          nonPjm: projectResponse?.projectTrackerHdr?.isReferralDivided
            ? projectResponse.projectTrackerHdr.nonPJMPercent
            : 0,
          turnerTownsend: projectResponse?.projectTrackerHdr?.isReferralDivided
            ? projectResponse.projectTrackerHdr.turnerTownsendPercent
            : 0,

          currency: projectResponse.currencyId || '',
          estimatedGrossRevenue:
            projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.estimatedRevenue,
          actualGrossRevenue:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.actualRevenue) || '',
          estimatedGrossMargin: projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.estimatedMargin,
          actualGrossMargin:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.actualMargin) || '',
          estimatedStartDate:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.expectedStartDate) === null
              ? projectResponse.projectTrackerHdr.expectedStartDate
              : moment(projectResponse.projectTrackerHdr.expectedStartDate, 'YYYY-MM-DD'),

          actualStartDate:
            projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.actualStartDate
              ? moment(projectResponse.projectTrackerHdr.actualStartDate, 'YYYY-MM-DD')
              : null,

          estimatedFinishDate:
            projectResponse.projectTrackerHdr.expectedCloseDate === null
              ? projectResponse.projectTrackerHdr.expectedCloseDate
              : moment(projectResponse.projectTrackerHdr.expectedCloseDate, 'YYYY-MM-DD'),

          actualFinishDate:
            projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.actualCloseDate
              ? moment(projectResponse.projectTrackerHdr.actualCloseDate, 'YYYY-MM-DD')
              : null,
          comments: projectResponse.projectDescription || '',
          receivingPersonnel:
            projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.receivingProjectManager
              ? projectResponse.projectTrackerHdr.receivingProjectManager
              : '',
          referralPersonnel:
            projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.referringPersonnel
              ? projectResponse.projectTrackerHdr.referringPersonnel
              : '',
          pifCheckbox: !!(projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.isPIFReviewed),

          receivingRegion:
            (projectResponse.projectTrackerHdr.receivingRegion &&
              projectResponse.projectTrackerHdr.receivingRegion.regionId) ||
            '',
          receivingSegment:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.receivingSegmentId) || '',
          receivingClient:
            projectResponse.projectTrackerHdr.receivingClient && projectResponse.projectTrackerHdr.receivingClient.id
              ? {
                  clientId: projectResponse.projectTrackerHdr.receivingClient.id,
                  clientName: projectResponse.projectTrackerHdr.receivingClient.name,
                }
              : '',
          receivingCustomClient:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.receivingCustomClient) || '',
          receivingDivision:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.receivingDivisionId) || '',
          receivingCustomDivision:
            (projectResponse.projectTrackerHdr && projectResponse.projectTrackerHdr.receivingCustomDivision) || '',
        };
        return {
          ...state,
          projectTrackerProject: projectInfo,
          mode: 'edit',
          attachedFileList:
            projectResponse.files && projectResponse.files.length > 0
              ? projectResponse.files
              : [{ originalFileName: '' }],
          attachedFileIds: projectResponse.fileIds && projectResponse.fileIds.length > 0 ? projectResponse.fileIds : [],
          kahuaManaged: !!projectResponse.kahuaManaged,
          loading: false,
        };
      } catch (err) {
        console.log('err---', err);
      }
    default:
      return state;
  }
};
