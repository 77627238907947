/* eslint-disable */
import {
  COST_PLAN_DELETE_START,
  COST_PLAN_DELETE_SUCCESS,
  COST_PLAN_DELETE_FAILURE,
  COST_PLAN_DELETE_RESET,
  COST_PLAN_STATE_UPDATE,
  COST_PLAN_OBJ_REQUESTED,
  GET_DEFAULT_WORKPACKAGE_START,
  GET_DEFAULT_WORKPACKAGE_FAILURE,
  GET_DEFAULT_WORKPACKAGE_SUCCESS,
  GET_WBS_START,
  GET_WBS_SUCCESS,
  GET_WBS_FAILURE,
} from './constants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  costPlanDeleteInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  costPlanCheckBlock: {
    costPlanCheckBlock: false,
  },
  costPlanRequestedObjDEF: {},
  defaultWorkpackages: [],
};

// eslint-disable-next-line complexity
const costPlanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COST_PLAN_DELETE_SUCCESS:
      return {
        ...state,
        costPlanDeleteInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        loading: false,
      };
    case COST_PLAN_DELETE_FAILURE:
      return {
        ...state,
        costPlanDeleteInfo: {
          ...action.payload,
          loading: false,
        },
        loading: false,
      };
    case COST_PLAN_DELETE_START:
      return {
        ...state,
        costPlanDeleteInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case COST_PLAN_DELETE_RESET:
      return {
        ...state,
        costPlanDeleteInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
        loading: false,
      };
    case COST_PLAN_STATE_UPDATE:
      return {
        ...state,
        costPlanCheckBlock: action.payload,
      };
    case COST_PLAN_OBJ_REQUESTED:
      return {
        ...state,
        costPlanRequestedObjDEF: action.payload ? action.payload : {},
      };
    case GET_DEFAULT_WORKPACKAGE_SUCCESS:
      return {
        ...state,
        defaultWorkpackages: action.payload,
        loading: false,
      };
    case GET_DEFAULT_WORKPACKAGE_START:
      return {
        ...state,
        defaultWorkpackages: [],
        loading: true,
      };
    case GET_DEFAULT_WORKPACKAGE_FAILURE:
      return {
        ...state,
        defaultWorkpackages: [],
        loading: false,
      };
    default:
      return state;
  }
};

export default costPlanReducer;
