/* eslint-disable */
import { roundToTwoDecimals } from 'util/utilityMethods';
import spacePlanDefaultData from './SpacePlanDefaultData';
import spacePlanDefaultDataSQM from './SpacePlanDefaultDataSQM';
import { getKeys } from './SpacePlanUtils';

export function doCalculations(spacePlanData) {
  console.log('spacePlanData ------------ ', spacePlanData);
  let spacePlanDefaultData1;
  if (spacePlanData.unit.unit === 'SQ MT') {
    spacePlanDefaultData1 = spacePlanDefaultDataSQM;
  } else {
    spacePlanDefaultData1 = spacePlanDefaultData;
  }
  const headCount = Number.parseInt(spacePlanData.spacePlanHdr.headCount);
  const sharingRatio = Number.parseFloat(spacePlanData.spacePlanHdr.sharingRatio);
  const seatDensity =
    spacePlanData.spacePlanHdr.seatDensity === 0 || spacePlanData.spacePlanHdr.seatDensity
      ? Number.parseInt(spacePlanData.spacePlanHdr.seatDensity)
      : '';
  const officeWorkstationRatio =
    spacePlanData.spacePlanHdr.officeVsWorkStationPercentage || spacePlanDefaultData1.officeVsWorkStationPercentage;
  let Seats = 0;
  if (sharingRatio > 0 && seatDensity > 0) {
    Seats = Math.ceil(headCount / sharingRatio);
  }
  let torlaArea = 0;
  let offices = 0;
  let workstations = 0;
  if (seatDensity > 0) {
    torlaArea = Seats * seatDensity;
    offices = Math.ceil(Seats * (officeWorkstationRatio / 100));
    workstations = Seats - offices;
  }
  spacePlanData.spacePlanHdr.seats = Seats;
  spacePlanData.spacePlanHdr.totalAreaRentable = Math.ceil(torlaArea);
  spacePlanData.spacePlanHdr.offices = offices;
  spacePlanData.spacePlanHdr.workStations = workstations;
  spacePlanData.spacePlanHdr.seatDensity = seatDensity;
  return spacePlanData;
}
export function doReverseCalculations(spacePlanData) {
  let spacePlanDefaultData1;
  if (spacePlanData.unit.unit === 'SQ MT') {
    spacePlanDefaultData1 = spacePlanDefaultDataSQM;
  } else {
    spacePlanDefaultData1 = spacePlanDefaultData;
  }

  const { sharingRatio } = spacePlanDefaultData1;
  const { seatDensity } = spacePlanDefaultData1;
  const seats = Math.ceil(spacePlanData.spacePlanHdr.totalAreaRentable / seatDensity);
  const headCount = Math.ceil(seats / sharingRatio);
  const officeWorkstationRatio =
    spacePlanData.spacePlanHdr.officeVsWorkStationPercentage || spacePlanDefaultData1.officeVsWorkStationPercentage;
  const offices = Math.ceil(seats * (officeWorkstationRatio / 100));
  const workstations = seats - offices;
  spacePlanData.spacePlanHdr.seats = seats;
  spacePlanData.spacePlanHdr.offices = offices;
  spacePlanData.spacePlanHdr.workStations = Math.round(workstations);
  spacePlanData.spacePlanHdr.seatDensity = seatDensity;
  spacePlanData.spacePlanHdr.headCount = headCount;
  spacePlanData.spacePlanHdr.sharingRatio = sharingRatio;
  return spacePlanData;
}
export function doSpaceCalculation(spaceData, spacePlanMock) {
  let spacePlanDefaultData1;
  if (spacePlanMock.unit.unit === 'SQ MT') {
    spacePlanDefaultData1 = spacePlanDefaultDataSQM;
  } else {
    spacePlanDefaultData1 = spacePlanDefaultData;
  }

  const spacePlanData = { ...spacePlanMock };
  const circulationFactor =
    spacePlanData.spacePlanHdr.circulationFactorPercentage || spacePlanDefaultData1.circulationFactorPercentage;
  const lossFactor = spacePlanData.spacePlanHdr.lossFactorPercentage || spacePlanDefaultData1.lossFactorPercentage;
  let workSpaceQuantityTotal = 0;
  spacePlanMock.spacePlanHdr.allocatedSpacePercentage = 0;
  spacePlanMock.spacePlanHdr.subTotalUsable = 0;
  spacePlanMock.spacePlanHdr.subTotalWithCirculationFactor = 0;
  spacePlanMock.spacePlanHdr.grandTotalWithLossFactorRentable = 0;
  const newSpaceData = spaceData.map((spaceObj) => {
    let totalAreaLossRsf = 0;
    let totalAreaCircRsf = 0;
    let totalAreaUsf = 0;
    let totalAreaRsfPercentage = 0;
    getKeys(spaceObj.area).forEach((item) => {
      const selectedSpaceId = spaceObj.area[item].selectedSpaceSizeId;
      let selectedSpaceTotal = 0;
      if (spaceObj.spaceName === 'Collaborative Space' && spaceObj.area[item].subSpaceName === 'Conference Room') {
        spaceObj.area[item].subSpaceIncCircRsf = 0;
        spaceObj.area[item].subSpaceIncLossRsf = 0;
        spaceObj.area[item].subSpacePercentage = 0;
        getKeys(spaceObj.area[item].data).forEach((id) => {
          const collabrateSpaceTotal =
            (spaceObj.area[item].data[id].totalUsable && Number.parseInt(spaceObj.area[item].data[id].totalUsable)) ||
            0;
          selectedSpaceTotal += collabrateSpaceTotal;
          spaceObj.area[item].data[id].inclCircFactor = roundToTwoDecimals(
            collabrateSpaceTotal + collabrateSpaceTotal * (circulationFactor / 100),
          );
          spaceObj.area[item].data[id].inclLossFactorRentable = roundToTwoDecimals(
            spaceObj.area[item].data[id].inclCircFactor +
              spaceObj.area[item].data[id].inclCircFactor * (lossFactor / 100),
          );
          spaceObj.area[item].data[id].percentageOfTotal =
            (spaceObj.area[item].data[id].inclLossFactorRentable / spacePlanData.spacePlanHdr.totalAreaRentable) * 100;
          spaceObj.area[item].subSpaceIncCircRsf +=
            collabrateSpaceTotal + collabrateSpaceTotal * (circulationFactor / 100);
          spaceObj.area[item].subSpaceIncLossRsf +=
            spaceObj.area[item].data[id].inclCircFactor +
            spaceObj.area[item].data[id].inclCircFactor * (lossFactor / 100);
          spaceObj.area[item].subSpacePercentage +=
            (spaceObj.area[item].data[id].inclLossFactorRentable / spacePlanData.spacePlanHdr.totalAreaRentable) * 100;
        });
      } else {
        workSpaceQuantityTotal +=
          spaceObj.spaceName === 'Workspace' &&
          !isNaN(Number.parseFloat(spaceObj.area[item].data[selectedSpaceId].quantity))
            ? Number.parseFloat(spaceObj.area[item].data[selectedSpaceId].quantity)
            : 0;

        selectedSpaceTotal =
          (spaceObj.area[item].data[selectedSpaceId].totalUsable &&
            Number.parseInt(spaceObj.area[item].data[selectedSpaceId].totalUsable)) ||
          0;
        spaceObj.area[item].subSpaceIncCircRsf = selectedSpaceTotal + selectedSpaceTotal * (circulationFactor / 100);
        spaceObj.area[item].subSpaceIncLossRsf =
          spaceObj.area[item].subSpaceIncCircRsf + spaceObj.area[item].subSpaceIncCircRsf * (lossFactor / 100);
        spaceObj.area[item].subSpacePercentage =
          (spaceObj.area[item].subSpaceIncLossRsf / spacePlanData.spacePlanHdr.totalAreaRentable) * 100;
        spaceObj.area[item].data[selectedSpaceId].inclCircFactor = roundToTwoDecimals(
          spaceObj.area[item].subSpaceIncCircRsf,
        );
        spaceObj.area[item].data[selectedSpaceId].inclLossFactorRentable = roundToTwoDecimals(
          spaceObj.area[item].subSpaceIncLossRsf,
        );
        spaceObj.area[item].data[selectedSpaceId].percentageOfTotal = spaceObj.area[item].subSpacePercentage;
      }
      totalAreaLossRsf += spaceObj.area[item].subSpaceIncLossRsf;
      totalAreaCircRsf += spaceObj.area[item].subSpaceIncCircRsf;
      totalAreaRsfPercentage += spaceObj.area[item].subSpacePercentage;
      totalAreaUsf += selectedSpaceTotal;
    });
    spaceObj.totalAreaLossRsf = roundToTwoDecimals(totalAreaLossRsf);
    spaceObj.totalAreaRsfPercentage = totalAreaRsfPercentage;
    spaceObj.totalAreaCircRsf = roundToTwoDecimals(totalAreaCircRsf);
    spaceObj.totalAreaUsf = roundToTwoDecimals(totalAreaUsf);
    if (spaceObj.spaceName === 'Workspace') {
      spacePlanMock.spacePlanHdr.workSpaceRentable = roundToTwoDecimals(totalAreaLossRsf);
      spacePlanMock.spacePlanHdr.workSpacePercentage = roundToTwoDecimals(totalAreaRsfPercentage);
    } else if (spaceObj.spaceName === 'Collaborative Space') {
      spacePlanMock.spacePlanHdr.allocatedCollaborativeSpaceRentable = roundToTwoDecimals(totalAreaLossRsf);
      spacePlanMock.spacePlanHdr.allocatedCollaborativeSpacePercentage = roundToTwoDecimals(totalAreaRsfPercentage);
    } else if (spaceObj.spaceName === 'Common Areas') {
      spacePlanMock.spacePlanHdr.allocatedCommonAreasRentable = roundToTwoDecimals(totalAreaLossRsf);
      spacePlanMock.spacePlanHdr.allocatedCommonAreasPercentage = roundToTwoDecimals(totalAreaRsfPercentage);
    }
    spacePlanMock.spacePlanHdr.allocatedSpacePercentage += roundToTwoDecimals(totalAreaRsfPercentage);
    spacePlanMock.spacePlanHdr.subTotalUsable += spaceObj.totalAreaUsf;
    return spaceObj;
  });
  let unAllocSpacePercentage = 0;
  if (spacePlanMock.spacePlanHdr.allocatedSpacePercentage) {
    unAllocSpacePercentage = 100 - roundToTwoDecimals(spacePlanMock.spacePlanHdr.allocatedSpacePercentage);
  }
  spacePlanMock.spacePlanHdr.unallocatedSpacePercentage = unAllocSpacePercentage < 0 ? 0 : unAllocSpacePercentage;
  spacePlanMock.spacePlanHdr.unallocatedCollaborativeSpacePercentage =
    (spacePlanMock.spacePlanHdr.unallocatedSpacePercentage * 40) / 100;
  spacePlanMock.spacePlanHdr.unallocatedCommonAreasPercentage =
    (spacePlanMock.spacePlanHdr.unallocatedSpacePercentage * 60) / 100;

  spacePlanMock.spacePlanHdr.unallocatedCollaborativeSpaceRentable = roundToTwoDecimals(
    (spacePlanMock.spacePlanHdr.unallocatedCollaborativeSpacePercentage *
      spacePlanData.spacePlanHdr.totalAreaRentable) /
      100,
  );
  spacePlanMock.spacePlanHdr.unallocatedCommonAreasRentable =
    (spacePlanMock.spacePlanHdr.unallocatedCommonAreasPercentage * spacePlanData.spacePlanHdr.totalAreaRentable) / 100;

  const unallocatedcollabSpacewithLoss =
    (spacePlanMock.spacePlanHdr.unallocatedCollaborativeSpaceRentable * 100) / (100 + lossFactor);
  const unallocatedcollabSpacewithCirc = (unallocatedcollabSpacewithLoss * 100) / (100 + circulationFactor);
  const unallocatedcommonSpacewithLoss =
    (spacePlanMock.spacePlanHdr.unallocatedCommonAreasRentable * 100) / (100 + lossFactor);
  const unallocatedcommonSpacewithCirc = (unallocatedcommonSpacewithLoss * 100) / (100 + circulationFactor);

  spacePlanMock.spacePlanHdr.subTotalUsable +=
    unallocatedcollabSpacewithCirc + roundToTwoDecimals(unallocatedcommonSpacewithCirc);

  spacePlanMock.spacePlanHdr.subTotalWithCirculationFactor = roundToTwoDecimals(
    spacePlanMock.spacePlanHdr.subTotalUsable + spacePlanMock.spacePlanHdr.subTotalUsable * (circulationFactor / 100),
  );
  spacePlanMock.spacePlanHdr.grandTotalWithLossFactorRentable =
    spacePlanMock.spacePlanHdr.subTotalWithCirculationFactor +
    roundToTwoDecimals(spacePlanMock.spacePlanHdr.subTotalWithCirculationFactor * (lossFactor / 100));
  spacePlanMock.spacePlanHdr.totalAreaRentable = spacePlanMock.spacePlanHdr.grandTotalWithLossFactorRentable;
  spacePlanMock.spacePlanHdr.calculatedSeatDensity = roundToTwoDecimals(
    spacePlanMock.spacePlanHdr.grandTotalWithLossFactorRentable / workSpaceQuantityTotal,
  );

  return [newSpaceData, spacePlanMock];
}
