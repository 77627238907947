/* eslint-disable */
import {
  GET_ACTIONS_PROJECTS_SUCCESS,
  GET_ACTIONS_PROJECTS_FAILURE,
  GET_ACTIONS_PROJECT_DETAILS_SUCCESS,
  GET_ACTIONS_PROJECT_DETAILS_FAILURE,
  GET_ACTIONS_PROJECTS_START,
  GET_ACTIONS_PROJECT_DETAILS_START,
  CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST,
  UPDATE_USER_ACCESS_STATUS_START,
  UPDATE_USER_ACCESS_STATUS_SUCCESS,
  UPDATE_USER_ACCESS_STATUS_FAILURE,
  UPDATE_COMMENTS_START,
  UPDATE_COMMENTS_SUCCESS,
  UPDATE_COMMENTS_FAILURE,
  IS_ACTIVE_ITEM_SET_TO_INITIAL,
} from './constants';

const INITIAL_STATE = {
  vendorProjects: {
    data: {
      recordsList: [],
    },
    item: {},
  },
  statusMap: {},
  pageSize: 10,
  totalRecords: 0,
  pageIndex: 0,
  isSearching: false,
  isPagination: false,
  apiUrl: '',
  apiBaseUrl: '',
  error: false,
  errorCode: '',
  loading: false,
  fusionProjects: [],
  fixedProjectDetails: {},
  fixedProjectSelected: false,
  userAccessData: {},
  statusError: false,
  statusAlert: '',
  currentProjectDetails: {
    uniqueKey: '',
  },
  projectDetails: {},
  CommentsSuccess: {},
  isActiveItemSetToInitial: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ACTIONS_PROJECTS_START:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ACTIONS_PROJECTS_SUCCESS:
      const setCurrent = {};
      const { fixedProjectDetails } = state;
      if (fixedProjectDetails && fixedProjectDetails.kahuaFusionDataId) {
        // setCurrent.currentProjectDetails = { ...fixedProjectDetails }
      }
      return {
        ...state,
        ...action.payload,
        ...setCurrent,
      };
    case GET_ACTIONS_PROJECTS_FAILURE:
      return {
        ...state,
        error: true,
        vendorProjects: { ...INITIAL_STATE.vendorProjects },
        currentProjectDetails: { ...INITIAL_STATE.currentProjectDetails },
        totalRecords: 0,
        pageIndex: 0,
      };
    case GET_ACTIONS_PROJECT_DETAILS_START:
      return {
        ...state,
        projectDetails: {},
        loading: true,
      };
    case GET_ACTIONS_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: action.payload,
        loading: false,
      };
    case GET_ACTIONS_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        projectDetails: action.payload,
        loading: false,
      };
    case UPDATE_USER_ACCESS_STATUS_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case UPDATE_USER_ACCESS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusAlert: 'success',
        userAccessData: action.payload,
      };
    case IS_ACTIVE_ITEM_SET_TO_INITIAL:
      return {
        ...state,
        isActiveItemSetToInitial: action.payload,
      };
    case UPDATE_USER_ACCESS_STATUS_FAILURE:
      return {
        ...state,
        statusError: true,
        loading: false,
        statusAlert: 'failure',
      };
    case UPDATE_COMMENTS_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case UPDATE_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        CommentsSuccess: action.payload,
      };
    case UPDATE_COMMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST:
      return {
        ...state,
        statusAlert: 'close',
        loading: false,
      };
    default:
      return state;
  }
};
