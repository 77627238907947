/* eslint-disable */
import { createSelector } from 'reselect';
import { useParams } from 'react-router';
import IntlMessages from 'util/IntlMessages';
import React from 'react';

const getConfigInfo = (state) => state && state.config;
const getQueryParams = (state) => state && state.router && state.router.location && state.router.location.search;
const moduleName = 'cbre-pjm-pds-my-actions';
const getActions = (state) => state && state[moduleName];

export const VendorProjectUrl = createSelector([getConfigInfo, getQueryParams], (configInfo, params) => {
  const { clientId } = useParams();
  return configInfo && configInfo.client && configInfo.client.coreBasicAuthUrl
    ? `${configInfo.client.coreBasicAuthUrl}fusion/projects`
    : '';
});

export const getMyActionsProjectListUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.approvalsApiUrl
    ? `${configInfo.client.approvalsApiUrl}actions`
    : '',
);

export const getPDSAPIUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.pdsApiUrl ? `${configInfo.client.pdsApiUrl}` : '',
);

export const getActionsProjectDetailUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.pdsApiUrl ? `${configInfo.client.approvalsApiUrl}actions` : '',
);

export const getAccessStatusAlertSuccessMessage = createSelector([getActions], (action) => {
  if (action.statusAlert === 'success') {
    const initialMsg = <IntlMessages id="cbre-pjm-pds-my-actions.alert.statusupdatesuccessmessage" />;
    return initialMsg;
  } else {
    const errorMsg = <IntlMessages id="cbre-pjm-pds-my-actions.alert.statusupdateerrormessage" />;
    return errorMsg;
  }
});
