export const GET_ESTIMATOR_PROJECTS_LIST_FILTER_SUCCESS =
  'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/GET_ESTIMATOR_PROJECTS_LIST_FILTER_SUCCESS';
export const GET_ESTIMATOR_PROJECTS_LIST_FILTER_FAILURE =
  'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/GET_ESTIMATOR_PROJECTS_LIST_FILTER_FAILURE';
export const GET_ESTIMATOR_PROJECTS_LIST_FILTER_RESET =
  'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/GET_ESTIMATOR_PROJECTS_LIST_FILTER_RESET';

export const OPEN_DELETE_ESTIMATOR_DIALOG = 'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/OPEN_DELETE_ESTIMATOR_DIALOG';
export const OPEN_DELETE_ESTIMATOR_DIALOG_SUCCESS =
  'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/OPEN_DELETE_ESTIMATOR_DIALOG_SUCCESS';
export const OPEN_DELETE_ESTIMATOR_DIALOG_FAILURE =
  'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/OPEN_DELETE_ESTIMATOR_DIALOG_FAILURE';
export const DELETE_ESTIMATOR_START = 'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/DELETE_ESTIMATOR_START';
export const DELETE_ESTIMATOR_SUCCESS = 'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/DELETE_ESTIMATOR_SUCCESS';
export const DELETE_ESTIMATOR_FAILURE = 'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/DELETE_ESTIMATOR_FAILURE';
export const CLOSE_DELETE_ESTIMATOR_TOAST = 'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/CLOSE_DELETE_ESTIMATOR_TOAST';
export const CLOSE_DELETE_ESTIMATOR_DIALOG = 'CBRE-PJM-ESTIMATOR-PROJECTS-LIST/CLOSE_DELETE_ESTIMATOR_DIALOG';
