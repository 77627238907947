import { GET_PROJECT_APPROVERS_LIST_SUCCESS, GET_PROJECT_APPROVERS_LIST_FAILURE } from './constants';

const INITIAL_STATE = {
  loading: false,
  error: false,
  messageCode: '',
  approversListData: [],
};

const approvalsStatusListReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_PROJECT_APPROVERS_LIST_SUCCESS:
      return {
        ...state,
        projectApproversListInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        approversListData: action.payload,
        loading: false,
      };
    case GET_PROJECT_APPROVERS_LIST_FAILURE:
      return {
        ...state,
        projectApproversListInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };

    default:
      return state;
  }
};

export default approvalsStatusListReducer;
