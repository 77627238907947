/* eslint-disable */
import {
  GET_PROGRAM_MANAGEMENT_CLIENTS_LIST_SUCCESS,
  GET_PROGRAM_MANAGEMENT_CLIENTS_LIST_FAILURE,
  GET_PROGRAM_MANAGEMENT_CLIENTS_LIST_RESET,
} from './constants';

const INITIAL_STATE = {
  clientsList: [],
  totalRecords: 0,
  error: false,
  errorCode: '',
  loading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROGRAM_MANAGEMENT_CLIENTS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        totalRecords: action.payload?.totalRecords,
        clientsList: action.payload?.recordsList,
      };
    case GET_PROGRAM_MANAGEMENT_CLIENTS_LIST_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_PROGRAM_MANAGEMENT_CLIENTS_LIST_RESET:
      return {
        ...state,
        loading: true,
        totalRecords: 0,
        clientsList: [],
      };

    default:
      return state;
  }
};
