/* eslint-disable */
import {
  HIDE_MESSAGE,
  INIT_URL,
  FUSION_USER_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  FUSION_USER_SIGNOUT_SUCCESS,
} from 'modules/cbre-pjm-Common/constants/ActionTypes';
import apiService from '../../../util/apiService';

export const userSignUp = (user) => ({
  type: SIGNUP_USER,
  payload: user,
});
export const userSignIn = (user) => ({
  type: SIGNIN_USER,
  payload: user,
});
export const userSignOut = () => ({
  type: SIGNOUT_USER,
});
export const fusionUserSignOut = () => ({
  type: FUSION_USER_SIGNOUT_SUCCESS,
});
export const userSignUpSuccess = (authUser) => ({
  type: SIGNUP_USER_SUCCESS,
  payload: authUser,
});

export const userSignInSuccess = (authUser) => ({
  type: SIGNIN_USER_SUCCESS,
  payload: authUser,
});

export const fusionUserSignInSuccess = (authUser) => ({
  type: FUSION_USER_SUCCESS,
  payload: authUser,
});
export const userSignOutSuccess = () => ({
  type: SIGNOUT_USER_SUCCESS,
});

export const showAuthMessage = (message) => ({
  type: SHOW_MESSAGE,
  payload: message,
});

export const setInitUrl = (url) => ({
  type: INIT_URL,
  payload: url,
});
export const showAuthLoader = () => ({
  type: ON_SHOW_LOADER,
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});
export const hideAuthLoader = () => ({
  type: ON_HIDE_LOADER,
});

export const reAuthenticateUser = (payload) => (dispatch) => {
  apiService.get(`${payload.reAuthUrl}`);
};
