export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENT_FAILURE = 'GET_CLIENT_FAILURE';
export const GET_CLIENT_RESET = 'GET_CLIENT_RESET';
export const GET_CLIENT_START = 'GET_PLAN_START';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const CLOSE_DELETE_USER_TOAST = 'CLOSE_DELETE_USER_TOAST';
export const UPDATE_USER_ACCESS_STATUS_START = 'UPDATE_USER_ACCESS_STATUS_START';
export const UPDATE_USER_ACCESS_STATUS_SUCCESS = 'UPDATE_USER_ACCESS_STATUS_SUCCESS';
export const UPDATE_USER_ACCESS_STATUS_FAILURE = 'UPDATE_USER_ACCESS_STATUS_FAILURE';
export const CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST = 'CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_USERS_RESET = 'GET_USERS_RESET';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USERS_START = 'GET_USERS_START';
export const GET_ALL_LOOKUPS_SUCCESS = 'USER_MANAGEMENT/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'USER_MANAGEMENT/GET_ALL_LOOKUPS_FAILURE';
export const DELETE_HIERARCHY_USER_TEAM_SUCCESS = 'USER_MANAGEMENT/DELETE_HIERARCHY_USER_TEAM_SUCCESS';
export const DELETE_HIERARCHY_USER_FAILURE = 'USER_MANAGEMENT/DELETE_HIERARCHY_USER_FAILURE';
export const DELETE_HIERARCHY_USER_START = 'USER_MANAGEMENT/DELETE_HIERARCHY_USER_START';
