/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */

/* eslint-disable */

import moment from 'moment';
import {
  ESTIMATOR_GET_ALL_LOOKUPS_SUCCESS,
  ESTIMATOR_GET_ALL_LOOKUPS_FAILURE,
  ESTIMATOR_ADD_NEW_PROJECT_START,
  ESTIMATOR_ADD_NEW_PROJECT_SUCCESS,
  ESTIMATOR_ADD_NEW_PROJECT_FAILURE,
  ESTIMATOR_ADD_NEW_PROJECT_EXISTS_FAILURE,
  ESTIMATOR_CLOSE_NEW_PROJECT_TOAST,
  ESTIMATOR_RESET_NEW_PROJECT_STATUS,
  ESTIMATOR_RESET_NEW_PROJECT_ERROR,
  ESTIMATOR_GET_PROJECT_BY_ID_START,
  ESTIMATOR_GET_PROJECT_BY_ID_SUCCESS,
  ESTIMATOR_SET_INITIALS,
} from './constants';

const defaultEstimatorNewProject = {
  projectTitle: '',
  occupancyDate: null,
  projectType: '',
  subProjectType: '',
  units: '',
  currency: '',

  addressRegion: '',
  addressCountry: '',
  addressCity: '',
  addressState: '',
  addressLine1: '',

  cbreContactEmail: '',
  clientContactEmail: '',
  preparedBy: '',
  preparedByDate: null,
  createProjectId: '',
  createProjectName: '',
};

const INITIAL_STATE = {
  allLegacy: {},
  simpleLookups: [],
  fetchLookupsError: false,
  estimatorAllLegacy: {},

  defaultEstimatorNewProject,
  estimatorNewProject: defaultEstimatorNewProject,

  loading: false,
  fetchLookupsStatus: '',
  createProjectStatus: '',
  createProjectError: '',
  messageCode: '',
  errorMessage: '',
  obj: {},
  alert: '',
  mode: '',
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case ESTIMATOR_GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        fetchLookupsStatus: 'success',
      };
    case ESTIMATOR_GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        fetchLookupsStatus: 'error',
      };
    case ESTIMATOR_SET_INITIALS:
      return {
        ...state,
        mode: 'add',
        ...action.payload,
      };
    case ESTIMATOR_ADD_NEW_PROJECT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case ESTIMATOR_ADD_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
        createProjectStatus: 'success',
        createProjectId: action.payload.responseData.projectId,
        createProjectName: action.payload.responseData.projectTitle,
        alert: '',
        createProjectError: '',
        messageCode: '',
      };
    case ESTIMATOR_ADD_NEW_PROJECT_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        createProjectStatus: 'duplicate',
        createProjectError: true,
        ...action.payload,
      };
    case ESTIMATOR_ADD_NEW_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        createProjectStatus: 'error',
        createProjectError: true,
        ...action.payload,
      };
    case ESTIMATOR_CLOSE_NEW_PROJECT_TOAST:
      return {
        ...state,
        ...action.payload,
        createProjectStatus: '',
        mode: '',
      };
    case ESTIMATOR_RESET_NEW_PROJECT_STATUS:
      return {
        ...state,
        createProjectStatus: '',
        mode: '',
      };
    case ESTIMATOR_RESET_NEW_PROJECT_ERROR:
      return {
        ...state,
        createProjectError: '',
        messageCode: '',
        createProjectStatus: '',
        errorMessage: '',
      };
    case ESTIMATOR_GET_PROJECT_BY_ID_START:
      return {
        ...state,
        loading: true,
      };
    case ESTIMATOR_GET_PROJECT_BY_ID_SUCCESS:
      const projectResponse = action.payload;

      console.log(action.payload);
      try {
        const projectInfo = {
          projectTitle: projectResponse.projectTitle || '',
          occupancyDate:
            projectResponse.occupancyDt === null
              ? projectResponse.occupancyDt
              : moment(projectResponse.occupancyDt, 'YYYY-MM-DD'),

          projectType: (projectResponse.projectType && projectResponse.projectType.projectTypeId) || '',
          subProjectType: (projectResponse.subProjectType && projectResponse.subProjectType.subProjectTypeId) || '',
          currency: (projectResponse.currency && projectResponse.currency.currencyId) || '',
          units: (projectResponse.unit && projectResponse.unit.unitId) || '',

          addressRegion: (projectResponse.region && projectResponse.region.regionId) || '',
          addressCountry: (projectResponse.country && projectResponse.country.countryId) || '',
          addressState: (projectResponse.state && projectResponse.state.stateId) || '',
          addressCity: (projectResponse.city && projectResponse.city.cityId) || '',
          addressLine1: projectResponse.street || '',

          cbreContactEmail: projectResponse.cbreContactEmailId || '',
          clientContactEmail: projectResponse.clientContactEmailId || '',
          preparedBy: projectResponse.preparedBy || '',
          preparedByDate:
            projectResponse.preparedbyDt === null
              ? projectResponse.preparedbyDt
              : moment(projectResponse.preparedbyDt, 'YYYY-MM-DD'),
        };
        return {
          ...state,
          estimatorNewProject: projectInfo,
          mode: 'edit',
          loading: false,
        };
      } catch (err) {
        console.log('err---', err);
        return null;
      }
    default:
      return state;
  }
};
