/* eslint-disable */
import { SET_PDS_DOCUMENT_LIST } from './constants';

const INITIAL_STATE = {
  PJM05: [],
  PJM03: [],
  PJM06: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PDS_DOCUMENT_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
