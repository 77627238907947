/* eslint-disable */
import { getMessage } from 'util/utilityMethods';
import {
  OPEN_ADD_PLAN_DIALOG,
  OPEN_EDIT_PLAN_DIALOG,
  CLOSE_ADD_PLAN_DIALOG,
  ADD_PLAN_START,
  ADD_PLAN_SUCCESS,
  EDIT_PLAN_SUCCESS,
  ADD_PLAN_FAILURE,
  ADD_PLAN_EXISTS_FAILURE,
  CLOSE_ADD_PLAN_TOAST,
  RESET_PLAN_EXISTS_FAILURE,
  OPEN_EDIT_PLAN_DIALOG_SUCCESS,
  OPEN_EDIT_PLAN_DIALOG_FAILURE,
  GET_ALL_CLIENTS_START,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_FAILURE,
  RESET_ADD_PLAN_TOAST,
  RESET_PLAN_MODIFIED,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_SUCCESS,
  PLAN_SUBMIT_START,
  PLAN_SUBMIT_END,
} from '../constants';

const INIT_STATE = {
  open: false,
  mode: '',
  client: {},
  allLegacy: {},
  loading: false,
  error: false,
  errorCode: '',
  alert: '',
  alertMessage: '',
  planId: '',
  planName: '',
  openedName: '',
  editDialogOpen: false,
  isAddEditSubmitTriggered: false,
  defaultValues: {},
  plansGridRenderCount: 0,
  getAllClientList: [],
  status: '',
  isPlanNameModified: false,
  showPlanNameModifiedAlert: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_ADD_PLAN_DIALOG:
      return {
        ...state,
        open: true,
        openedName: action.payload,
        mode: 'add',
      };
    /* case OPEN_EDIT_PLAN_DIALOG:
            return {
                ...state,
                open: true,
                mode: 'edit'
            } */
    case OPEN_EDIT_PLAN_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        openedName: action.payload?.openedName,
        mode: 'edit',
        client: action.payload?.data,
        planId: action.payload?.data?.planId,
        editDialogOpen: true,
        loading: false,
        status: '',
      };
    case OPEN_EDIT_PLAN_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        openedName: '',
        mode: '',
        loading: false,
      };
    case CLOSE_ADD_PLAN_DIALOG:
      return {
        ...state,
        open: false,
        openedName: '',
        mode: '',
        planId: '',
        editDialogOpen: false,
        loading: false,
      };
    case ADD_PLAN_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.payload,
        alert: 'success',
        alertMessage: getMessage({ id: 'msg.client.success' }),
        open: false,
        openedName: '',
        plansGridRenderCount: state.plansGridRenderCount + 1,
        // mode: ''
      };
    case EDIT_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        // client: action.payload,
        alert: 'success',
        alertMessage: getMessage({ id: 'msg.client.success' }),
        open: false,
        // planName: action.payload.planName,
        editDialogOpen: false,
        plansGridRenderCount: state.plansGridRenderCount + 1,
        //   mode: ''
        status: 'success',
        ...action.payload,
      };
    case ADD_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        defaultValues: action.payload.plan,
      };
    case ADD_PLAN_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload.messageCode,
        defaultValues: action.payload.plan,
      };
    case RESET_PLAN_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: false,
        errorCode: '',
      };
    case CLOSE_ADD_PLAN_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        showPlanNameModifiedAlert: false,
      };
    case RESET_ADD_PLAN_TOAST:
      return {
        ...state,
        alert: '',
        alertMessage: '',
        status: '',
      };
    case GET_ALL_CLIENTS_START: {
      return {
        ...state,
        loading: false,
        getAllClientList: action.payload,
      };
    }
    case RESET_PLAN_MODIFIED:
      return {
        ...state,
        isPlanNameModified: false,
        showPlanNameModifiedAlert: true,
      };
    case GET_ALL_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllClientList: action.payload,
      };
    case GET_ALL_CLIENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case PLAN_SUBMIT_START:
      return {
        ...state,
        isAddEditSubmitTriggered: true,
      };
    case PLAN_SUBMIT_END:
      return {
        ...state,
        isAddEditSubmitTriggered: false,
      };
    default:
      return state;
  }
};
