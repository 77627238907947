export const OPEN_DELETE_PROGRAM_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/OPEN_DELETE_PROGRAM_DIALOG';
export const OPEN_DELETE_PROGRAM_DIALOG_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/OPEN_DELETE_PROGRAM_DIALOG_SUCCESS';
export const OPEN_DELETE_PROGRAM_DIALOG_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/OPEN_DELETE_PROGRAM_DIALOG_FAILURE';
export const DELETE_PROGRAM_START = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/DELETE_PROGRAM_START';
export const DELETE_PROGRAM_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/DELETE_PROGRAM_FAILURE';
export const CLOSE_DELETE_PROGRAM_TOAST = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/CLOSE_DELETE_PROGRAM_TOAST';
export const CLOSE_DELETE_PROGRAM_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROGRAM/CLOSE_DELETE_PROGRAM_DIALOG';
