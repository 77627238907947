export const setRedirectPath = (path) => {
  try {
    sessionStorage.setItem('redirectAfterSegmentSelection', JSON.stringify(path));
  } catch (error) {
    console.error('Failed to set redirect path in sessionStorage', error);
  }
};

export const getRedirectPath = () => {
  try {
    const path = sessionStorage.getItem('redirectAfterSegmentSelection');
    return path ? JSON.parse(path) : null;
  } catch (error) {
    console.error('Failed to get redirect path from sessionStorage', error);
    return null;
  }
};

export const clearRedirectPath = () => {
  try {
    sessionStorage.removeItem('redirectAfterSegmentSelection');
  } catch (error) {
    console.error('Failed to clear redirect path from sessionStorage', error);
  }
};
