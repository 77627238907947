import {
  FUNDING_REQUEST_DELETE_START,
  FUNDING_REQUEST_GET_SUCCESS,
  FUNDING_REQUEST_GET_START,
  FUNDING_REQUEST_GET_FAILED,
  FUNDING_REQUEST_DELETE_SUCCESS,
  FUNDING_REQUEST_DELETE_FAILED,
  FUNDING_REQUEST_DELETE_RESET,
  FUNDING_REQUEST_DETAILS_GET_SUCCESS,
  FUNDING_REQUEST_DETAILS_GET_START,
  FUNDING_REQUEST_DETAILS_GET_FAILED,
  FUNDING_REQUEST_DETAILS_GET_RESET,
  GET_COST_STRUCTURE_FAILURE,
  GET_COST_STRUCTURE_START,
  GET_COST_STRUCTURE_SUCCESS,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_START,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_SUCCESS,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_FAILED,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_RESET,
  FUNDING_REQUEST_SUBMIT_START,
  FUNDING_REQUEST_SUBMIT_SUCCESS,
  FUNDING_REQUEST_SUBMIT_FAILED,
  FUNDING_REQUEST_REVOKE_START,
  FUNDING_REQUEST_REVOKE_SUCCESS,
  FUNDING_REQUEST_REVOKE_FAILED,
  FUNDING_REQUEST_REVOKE_RESET,
  FUNDING_REQUEST_VALIDATION_START,
  FUNDING_REQUEST_VALIDATION_SUCCESS,
  FUNDING_REQUEST_VALIDATION_RESET,
  FORECAST_PROJECT_DETAILS_GET_START,
  FORECAST_PROJECT_DETAILS_GET_SUCCESS,
  FORECAST_PROJECT_DETAILS_GET_FAILED,
} from './constants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  fundingRequestTypes: [],
  fundingRequestDeleteInfo: {
    error: false,
    message: '',
    loading: false,
  },
  fundingRequestDetails: {},
  defaultCostStructure: [],
  fundingRequestInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  fundingRequestUpsertInfo: {
    message: '',
    data: {},
    messageCode: '',
    loading: false,
  },
  revokeData: {
    message: '',
    messageCode: '',
    loading: false,
  },
  validationStarted: false,
  forecastDetails: {
    message: '',
    messageCode: '',
    loading: false,
    error: false,
  },
};

// eslint-disable-next-line complexity,default-param-last
const fundingRequestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FUNDING_REQUEST_DELETE_START:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: '',
          error: false,
          messageCode: '',
          loading: true,
        },
      };
    case FUNDING_REQUEST_DELETE_RESET:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: '',
          error: false,
          messageCode: '',
          loading: false,
        },
      };
    case FUNDING_REQUEST_DELETE_SUCCESS:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: action.payload,
          messageCode: '',
          loading: false,
        },
      };
    case FUNDING_REQUEST_DELETE_FAILED:
      return {
        ...state,
        fundingRequestDeleteInfo: {
          message: action.payload,
          error: true,
          messageCode: '',
          loading: false,
        },
        loading: false,
      };
    case FUNDING_REQUEST_GET_START:
      return {
        ...state,
        loading: true,
      };
    case FUNDING_REQUEST_GET_SUCCESS:
      return {
        ...state,
        fundingRequestTypes: action?.payload || [],
        loading: false,
      };
    case FUNDING_REQUEST_GET_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FUNDING_REQUEST_SUBMIT_START:
      return {
        ...state,
        loading: true,
      };
    case FUNDING_REQUEST_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case FUNDING_REQUEST_SUBMIT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FUNDING_REQUEST_REVOKE_START:
      return {
        ...state,
        loading: true,
        revokeData: {
          message: '',
          messageCode: '',
          loading: true,
        },
      };
    case FUNDING_REQUEST_REVOKE_RESET:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case FUNDING_REQUEST_REVOKE_SUCCESS:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
      };
    case FUNDING_REQUEST_REVOKE_FAILED:
      return {
        ...state,
        loading: false,
        revokeData: {
          message: 'failure',
          loading: false,
          messageCode: action.payload,
        },
      };
    case FUNDING_REQUEST_DETAILS_GET_START:
      return {
        ...state,
        loading: true,
      };
    case FUNDING_REQUEST_DETAILS_GET_SUCCESS:
      return {
        ...state,
        fundingRequestDetails: action?.payload || {},
        fundingRequestInfo: {
          message: 'success',
          messageCode: '',
          loading: false,
        },
        loading: false,
      };
    case FUNDING_REQUEST_DETAILS_GET_FAILED:
      return {
        ...state,
        loading: false,
        fundingRequestInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case FUNDING_REQUEST_DETAILS_GET_RESET:
      return {
        ...state,
        loading: false,
        fundingRequestInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case FORECAST_PROJECT_DETAILS_GET_START:
      return {
        ...state,
        loading: true,
      };
    case FORECAST_PROJECT_DETAILS_GET_SUCCESS:
      return {
        ...state,
        forecastDetails: {
          ...(action?.payload?.foreCastData || { forecasts: [] }),
        },
        loading: false,
      };
    case FORECAST_PROJECT_DETAILS_GET_FAILED:
      return {
        ...state,
        loading: false,
        forecastDetails: {
          message: '',
          messageCode: '',
          loading: false,
          error: true,
        },
      };
    case FUNDING_REQUEST_DETAILS_SAVE_UPDATE_START:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: '',
          data: {},
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case FUNDING_REQUEST_DETAILS_SAVE_UPDATE_SUCCESS:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: 'success',
          data: action.payload,
          messageCode: '',
          loading: false,
        },
        loading: false,
      };
    case FUNDING_REQUEST_DETAILS_SAVE_UPDATE_FAILED:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: 'failure',
          data: { message: action.payload.message },
          messageCode: action.payload.messageCode,
          loading: false,
        },
        loading: false,
      };
    case FUNDING_REQUEST_DETAILS_SAVE_UPDATE_RESET:
      return {
        ...state,
        fundingRequestUpsertInfo: {
          message: '',
          data: {},
          messageCode: '',
          loading: false,
        },
        validationStarted: true,
        loading: false,
      };
    case GET_COST_STRUCTURE_SUCCESS:
      return {
        ...state,
        defaultCostStructure: action.payload,
        loading: false,
      };
    case GET_COST_STRUCTURE_START:
      return {
        ...state,
        defaultCostStructure: [],
        loading: true,
      };
    case GET_COST_STRUCTURE_FAILURE:
      return {
        ...state,
        defaultCostStructure: [],
        loading: false,
      };
    case FUNDING_REQUEST_VALIDATION_START:
      return {
        ...state,
        validationStarted: true,
        loading: true,
      };
    case FUNDING_REQUEST_VALIDATION_SUCCESS:
      return {
        ...state,
        validationStarted: false,
        loading: false,
      };
    case FUNDING_REQUEST_VALIDATION_RESET:
      return {
        ...state,
        validationStarted: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default fundingRequestReducer;
