/* eslint-disable */
import {
  GET_CPP_LIST_FILTER_SUCCESS,
  GET_CPP_LIST_FILTER_FAILURE,
  GET_CPP_LIST_FILTER_RESET,
  GET_CPP_DIVISION_FILTER_SUCCESS,
  GET_CPP_DIVISION_FILTER_FAILURE,
  GET_CPP_DIVISION_FILTER_RESET,
  OPEN_DELETE_CPP_DIALOG,
  CLOSE_DELETE_CPP_DIALOG,
  DELETE_CPP_START,
  DELETE_CPP_SUCCESS,
  DELETE_CPP_FAILURE,
  CLOSE_DELETE_CPP_TOAST,
  OPEN_DELETE_CPP_DIALOG_SUCCESS,
  OPEN_DELETE_CPP_DIALOG_FAILURE,
} from './constants';

const INITIAL_STATE = {
  planHdrDetails: {},
  filterHdrDetails: {},
  divisionFilterDetails: {},
  error: false,
  errorCode: '',

  open: false,
  mode: '',
  loading: false,
  alertDeleteProject: '',
  alertMessage: '',
  projectId: '',
  projectTitle: '',
  deleteDialogOpen: false,
  defaultValues: {},
  projectsGridRenderCount: 0,
  showDeleteToaster: false,
  handleAlertClose: () => {},
  routeChangeFlag: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CPP_LIST_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterHdrDetails: action.payload,
      };
    case GET_CPP_LIST_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_CPP_LIST_FILTER_RESET:
      return {
        ...state,
        loading: false,
        filterHdrDetails: {},
      };

    case GET_CPP_DIVISION_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        divisionFilterDetails: action.payload,
      };
    case GET_CPP_DIVISION_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_CPP_DIVISION_FILTER_RESET:
      return {
        ...state,
        loading: false,
        divisionFilterDetails: {},
      };
    case OPEN_DELETE_CPP_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,
      };
    case OPEN_DELETE_CPP_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        deleteDialogOpen: true,
      };
    case OPEN_DELETE_CPP_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_DELETE_CPP_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        projectId: '',
        deleteDialogOpen: false,
      };
    case DELETE_CPP_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_CPP_SUCCESS:
      return {
        ...state,
        loading: false,
        alertDeleteProject: 'success',
        alertMessage: action.payload.deleteProjectMsgSuccess,
        open: false,
        deleteDialogOpen: false,
        projectsGridRenderCount: state.projectsGridRenderCount + 1,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case DELETE_CPP_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alertDeleteProject: 'failure',
        alertMessage: action.payload.deleteProjectMsgFailure,
        open: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_CPP_TOAST:
      return {
        ...state,
        alertDeleteProject: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    default:
      return state;
  }
};
