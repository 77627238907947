/* eslint-disable */
import {
  GET_FUSION_PROJECTS_SUCCESS,
  GET_FUSION_PROJECTS_FAILURE,
  GET_FUSION_PROJECT_DETAILS_SUCCESS,
  GET_FUSION_PROJECT_DETAILS_FAILURE,
  GET_FUSION_PROJECTS_START,
  GET_FUSION_STATUS_LIST_SUCCESS,
  GET_FUSION_PROJECT_DETAILS_START,
  UPDATE_FUSION_PROJECT_STATUS_SUCCESS,
  UPDATE_FUSION_PROJECT_STATUS_START,
} from './constants';

const INITIAL_STATE = {
  vendorProjects: {
    data: {
      recordList: [],
    },
    item: {},
  },
  statusMap: {},
  pageSize: 10,
  totalRecords: 0,
  pageIndex: 0,
  isSearching: false,
  isPagination: false,
  apiUrl: '',
  apiBaseUrl: '',
  error: false,
  errorCode: '',
  loading: false,
  fusionProjects: [],
  fixedProjectDetails: {},
  fixedProjectSelected: false,
  currentProjectDetails: {
    uniqueKey: '',
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FUSION_PROJECTS_START:
      return {
        ...state,
        ...action.payload,
      };
    case GET_FUSION_PROJECTS_SUCCESS:
      const setCurrent = {};
      const { fixedProjectDetails } = state;
      if (fixedProjectDetails && fixedProjectDetails.kahuaFusionDataId) {
        // setCurrent.currentProjectDetails = { ...fixedProjectDetails }
      }
      return {
        ...state,
        ...action.payload,
        ...setCurrent,
      };
    case GET_FUSION_PROJECTS_FAILURE:
      return {
        ...state,
        error: true,
        vendorProjects: { ...INITIAL_STATE.vendorProjects },
        currentProjectDetails: { ...INITIAL_STATE.currentProjectDetails },
        totalRecords: 0,
        pageIndex: 0,
      };
    case GET_FUSION_PROJECT_DETAILS_START:
      return {
        ...state,
        ...action.payload,
      };
    case GET_FUSION_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case GET_FUSION_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_FUSION_STATUS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_FUSION_PROJECT_STATUS_START:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_FUSION_PROJECT_STATUS_SUCCESS:
      const { vendorProjects, fixedProjectSelected } = state;
      const { data } = vendorProjects;
      const { currentProjectDetails } = action.payload;

      if (fixedProjectSelected) {
        const item1 = { ...currentProjectDetails.status };
        const newVendorProjects = {
          ...state.vendorProjects,
          item: {
            ...state.vendorProjects.item,
            status: item1,
          },
        };
        const newFixedProjectDetails = {
          ...state.fixedProjectDetails,
          participationStatus: item1,
        };
        return {
          ...state,
          ...action.payload,
          vendorProjects: newVendorProjects,
          fixedProjectDetails: newFixedProjectDetails,
        };
      }
      let dataItems = [...data.recordList];
      const dataItem = dataItems.map((item) => {
        if (item.kahuaFusionDataId === currentProjectDetails.kahuaFusionDataId) {
          return { ...item, status: { ...currentProjectDetails.status } };
        }
        return item;
      });
      return {
        ...state,
        ...action.payload,
        vendorProjects: {
          ...state.vendorProjects,
          data: {
            totalRecords: state.vendorProjects.data.totalRecords,
            recordList: dataItem,
          },
        },
      };
    default:
      return state;
  }
};
