import { createSelector } from 'reselect';

const getConfigInfo = (state) => state && state.config;
const getAuthInfo = (state) => state && state.auth;
const getClientInfo = (state) => state && state.commonClients;
const getCommon = (state) => state && state.common;

export const getLogOutUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.uiBaseUrl ? `${configInfo.client.uiBaseUrl}logout` : '',
);

export const getUserReAuthUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl ? `${configInfo.client.coreUrl}user/auth` : '',
);

export const updateUserLocaleUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl ? `${configInfo.client.coreUrl}user/locale` : '',
);

export const getPermissionsList = createSelector([getAuthInfo], (authInfo) => authInfo.permissions);

export const getClient = createSelector([getClientInfo], (clientInfo) => clientInfo.clientInfo);

export const getLegacyFilterApiUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl
    ? `${configInfo.client.coreUrl}lookup/getAllLegacy?region=1&sector=1&country=1&segmentUniqueCode=LA&state=1&city=1&projectType=1&unit=1&currency=1&projectStatus=1&appCode=PJM02&skipCache=true`
    : '',
);

export const getSimpleLookupsFilterApiUrl = createSelector([getConfigInfo], (configInfo) =>
  configInfo && configInfo.client && configInfo.client.coreUrl
    ? `${configInfo.client.coreUrl}lookup/getSimpleLookups?skipCache=true`
    : '',
);

export const simpleLookupsMappedData = createSelector([getCommon], (newProject) => {
  const { simpleLookups } = newProject;
  const mappedObject = {};
  simpleLookups?.map((item) => {
    mappedObject[item.lookupTypeCode] =
      item.lookupTypeValues && item.lookupTypeValues.length > 0
        ? item.lookupTypeValues.sort((a, b) => a.name?.localeCompare(b.name))
        : [];
  });
  return mappedObject;
});
