/* eslint-disable */
import {
  GET_TRACKER_PROJECTS_LIST_FILTER_SUCCESS,
  GET_TRACKER_PROJECTS_LIST_FILTER_FAILURE,
  GET_TRACKER_PROJECTS_LIST_FILTER_RESET,
  OPEN_DELETE_PROJECTTRACKER_DIALOG,
  CLOSE_DELETE_PROJECTTRACKER_DIALOG,
  DELETE_PROJECTTRACKER_START,
  DELETE_PROJECTTRACKER_SUCCESS,
  DELETE_PROJECTTRACKER_FAILURE,
  CLOSE_DELETE_PROJECTTRACKER_TOAST,
  OPEN_DELETE_PROJECTTRACKER_DIALOG_SUCCESS,
  OPEN_DELETE_PROJECTTRACKER_DIALOG_FAILURE,
} from './constants';

const INITIAL_STATE = {
  planHdrDetails: {},
  filterHdrDetails: {},
  error: false,
  errorCode: '',
  open: false,
  mode: '',
  loading: false,
  alertDeleteProject: '',
  alertMessage: '',
  projectId: '',
  trackerId: '',
  projectTitle: '',
  deleteDialogOpen: false,
  defaultValues: {},
  projectsGridRenderCount: 0,
  showDeleteToaster: false,
  handleAlertClose: () => {},
  routeChangeFlag: false,
  isEditAllowed: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRACKER_PROJECTS_LIST_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterHdrDetails: action.payload,
      };
    case GET_TRACKER_PROJECTS_LIST_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_TRACKER_PROJECTS_LIST_FILTER_RESET:
      return {
        ...state,
        loading: false,
        filterHdrDetails: {},
      };

    /* Project Delete */
    case OPEN_DELETE_PROJECTTRACKER_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        trackerId: action.payload.trackerId,
        enabled: action.payload.enabled,
        routeChangeFlag: action.payload.routeChangeFlag,
      };
    case OPEN_DELETE_PROJECTTRACKER_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        mode: 'delete',
        projectId: action.payload.projectId,
        trackerId: action.payload.trackerId,
        deleteDialogOpen: true,
      };
    case OPEN_DELETE_PROJECTTRACKER_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        mode: '',
      };
    case CLOSE_DELETE_PROJECTTRACKER_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        projectId: '',
        trackerId: '',
        deleteDialogOpen: false,
      };
    case DELETE_PROJECTTRACKER_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case DELETE_PROJECTTRACKER_SUCCESS:
      return {
        ...state,
        loading: false,
        alertDeleteProject: 'success',
        alertMessage: action.payload.deleteProjectMsgSuccess,
        open: false,
        deleteDialogOpen: false,
        projectsGridRenderCount: state.projectsGridRenderCount + 1,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case DELETE_PROJECTTRACKER_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        alertDeleteProject: 'failure',
        alertMessage: action.payload.deleteProjectMsgFailure,
        open: false,
        deleteDialogOpen: false,
        showDeleteToaster: true,
        handleAlertClose: action.payload.handleAlertClose,
      };
    case CLOSE_DELETE_PROJECTTRACKER_TOAST:
      return {
        ...state,
        alertDeleteProject: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    default:
      return state;
  }
};
