const underScoreToCamelCase = (string) => string.toLowerCase().replace(/(_\w)/g, (k) => k[1].toUpperCase());
function parseEnvVar(name) {
  let val = process.env[name];
  try {
    val = val === 'undefined' ? undefined : JSON.parse(val);
  } catch (error) {
    // ignore error
  }
  return val;
}
const envLocalVarState = Object.keys(process.env).reduce(
  (varObj, envVar) => {
    const regexExec = /^REACT_APP_CBRE_(BROWSER_)?([\dA-Z_]+)$/.exec(envVar);
    if (!regexExec) return varObj;

    const envVarNameSegment = regexExec[2];
    const configName = underScoreToCamelCase(envVarNameSegment);
    const parsedEnvVar = parseEnvVar(envVar);

    if (regexExec[1]) {
      // eslint-disable-next-line no-param-reassign
      varObj.client[configName] = parsedEnvVar;
    }

    return varObj;
  },
  { client: {} },
);

export default envLocalVarState;
