import { shallowEqual, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { UserHierarchyAccessProvider } from './UserHierarchyAccessProvider';

const AuthProvider = (props) => {
  const { children, initURL } = props;
  const { client } = useSelector(({ config }) => config, shallowEqual);
  const { isSystemAdmin } = useSelector(({ auth }) => auth, shallowEqual);
  if (location.pathname === '/') {
    if (
      (client?.maintenanceMode === 'YES' && isSystemAdmin) ||
      !client?.maintenanceMode ||
      client?.maintenanceMode !== 'YES'
    ) {
      console.log('initURL---', initURL);
      if (initURL === '' || initURL === '/') {
        console.log('dashoard redirection');
        //return props.history.push("/dashboard")
        return <Redirect to="/dashboard" />;
      }
      //return props.history.push(initURL)
      return <Redirect to={initURL} />;
    } else {
      //return props.history.push("/maintenance");
      return <Redirect to="/maintenance" />;
    }
  } else if (location.pathname !== '/maintenance' && client?.maintenanceMode === 'YES' && !isSystemAdmin) {
    //return props.history.push("/maintenance");
    return <Redirect to="/maintenance" />;
  }

  return <UserHierarchyAccessProvider>{children}</UserHierarchyAccessProvider>;
};

export default AuthProvider;
