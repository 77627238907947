/* eslint-disable complexity */
import {
  GET_TEMPLATE_DATA_START,
  GET_TEMPLATE_DATA_SUCCESS,
  GET_TEMPLATE_DATA_FAILURE,
  GET_KAHUA_LOCATION_START,
  GET_KAHUA_LOCATION_SUCCESS,
  GET_KAHUA_LOCATION_FAILURE,
  RESET_KAHUA_TEMPLATE,
  EXPORT_TO_KAHUA_SUCCESS,
  EXPORT_TO_KAHUA_FAILURE,
  EXPORT_TO_KAHUA_START,
  EXPORT_TO_KAHUA_RESET,
} from './constants';

const INIT_STATE = {
  kahuaLocationData: [],
  KahuaTemplateData: [],
  loading: false,
  error: false,
  errorCode: '',
  exportToKahuaStatus: '',
  exportToKahuaLoading: false,
};

/* eslint-disable-next-line import/no-anonymous-default-export, default-param-last */
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        KahuaTemplateData: action.payload,
      };
    case GET_TEMPLATE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_TEMPLATE_DATA_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case RESET_KAHUA_TEMPLATE:
      return {
        ...state,
        loading: false,
        KahuaTemplateData: [],
      };
    case GET_KAHUA_LOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        kahuaLocationData: action.payload,
      };
    case GET_KAHUA_LOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        kahuaLocationData: [],
      };
    case GET_KAHUA_LOCATION_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case EXPORT_TO_KAHUA_START:
      return {
        ...state,
        exportToKahuaLoading: true,
      };
    case EXPORT_TO_KAHUA_SUCCESS:
      return {
        ...state,
        exportSuccessResponse: action.payload,
        exportToKahuaLoading: false,
        exportToKahuaStatus: 'success',
      };
    case EXPORT_TO_KAHUA_FAILURE:
      return {
        ...state,
        exportToKahuaLoading: false,
        exportToKahuaStatus: 'failure',
      };
    case EXPORT_TO_KAHUA_RESET:
      return {
        ...state,
        exportToKahuaStatus: '',
      };
    default:
      return state;
  }
};
