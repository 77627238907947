import {
  FORECAST_DETAILS_GET_SUCCESS,
  FORECAST_DETAILS_GET_START,
  FORECAST_DETAILS_GET_FAILED,
  FORECAST_DETAILS_GET_RESET,
  FORECAST_DETAILS_SUBMIT_SUCCESS,
  FORECAST_DETAILS_SUBMIT_RESET,
  FORECAST_FINANCIAL_OVERVIEW_START,
  FORECAST_FINANCIAL_OVERVIEW_SUCCESS,
  FORECAST_FINANCIAL_OVERVIEW_FAILED,
} from './constants';

const INITIAL_STATE = {
  error: false,
  errorCode: '',
  loading: false,
  forecastDetails: { forecasts: [] },
  workPackageData: [],
  forecastDetailsInfo: {
    message: '',
    messageCode: '',
    loading: false,
    error: false,
  },
  financialOverview: {
    loading: false,
    error: null,
    data: null,
  },
};

// eslint-disable-next-line complexity,default-param-last
const forecastReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FORECAST_DETAILS_GET_START:
      return {
        ...state,
        forecastDetailsInfo: {
          message: '',
          messageCode: '',
          loading: true,
          error: false,
        },
        forecastDetailsSubmitInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error: false,
        },
        loading: true,
      };
    case FORECAST_DETAILS_GET_SUCCESS:
      return {
        ...state,
        forecastDetails: {
          ...(action?.payload?.foreCastData || { forecasts: [] }),
        },
        workPackageData:
          action?.payload?.workPackageData?.sort((a, b) => a.defaultCostStructureId - b.defaultCostStructureId) || [],
        forecastDetailsInfo: {
          message: 'success',
          messageCode: '',
          loading: false,
          error: false,
        },
        loading: false,
      };
    case FORECAST_DETAILS_SUBMIT_SUCCESS:
      return {
        ...state,
        forecastDetailsSubmitInfo: {
          message: 'success',
          messageCode: '',
          loading: false,
          error: false,
        },
        loading: false,
      };
    case FORECAST_DETAILS_GET_FAILED:
      return {
        ...state,
        loading: false,
        forecastDetailsInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error: true,
        },
      };
    case FORECAST_DETAILS_GET_RESET:
      return {
        ...state,
        loading: false,
        workPackageData: [],
        forecastDetails: { forecasts: [] },
        forecastDetailsInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error: false,
        },
      };
    case FORECAST_DETAILS_SUBMIT_RESET:
      return {
        ...state,
        forecastDetailsSubmitInfo: {
          message: '',
          messageCode: '',
          loading: false,
          error: false,
        },
      };
    case FORECAST_FINANCIAL_OVERVIEW_START:
      return {
        ...state,
        financialOverview: {
          loading: true,
          error: null,
          data: null,
        },
      };
    case FORECAST_FINANCIAL_OVERVIEW_SUCCESS:
      return {
        ...state,
        financialOverview: {
          loading: false,
          error: null,
          data: action.payload,
        },
      };
    case FORECAST_FINANCIAL_OVERVIEW_FAILED:
      return {
        ...state,
        financialOverview: {
          loading: false,
          error: action.payload,
          data: null,
        },
      };
    default:
      return state;
  }
};

export default forecastReducer;
