import { GET_CLIENT_START, GET_CLIENT_SUCCESS, GET_CLIENT_FAILURE } from '../constants/ClientInfo';

const INIT_STATE = {
  client: {},
  clientInfo: {},
  error: false,
  errorCode: '',
  clientId: '',
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_START:
      return {
        ...state,
        clientId: '',
        clientInfo: {},
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        clientInfo: action.payload,
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
      };
    default:
      return state;
  }
};
