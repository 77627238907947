import { getMessage } from 'util/utilityMethods';

export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-project-new-project/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-lite-project-new-project/SET_LOADING_FALSE';
export const GET_ALL_LOOKUPS_SUCCESS = 'cbre-pjm-principal-lite-project-new-project/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'cbre-pjm-principal-lite-project-new-project/GET_ALL_LOOKUPS_FAILURE';
export const GET_ALL_LOOKUPS_START = 'cbre-pjm-principal-lite-project-new-project/GET_ALL_LOOKUPS_START';
export const GET_LEGACY_COUNTRY_START = 'cbre-pjm-principal-lite-project-new-project/GET_LEGACY_COUNTRY_START';
export const GET_LEGACY_COUNTRY_SUCCESS = 'cbre-pjm-principal-lite-project-new-project/GET_LEGACY_COUNTRY_SUCCESS';
export const GET_LEGACY_COUNTRY_FAILURE = 'cbre-pjm-principal-lite-project-new-project/GET_LEGACY_COUNTRY_FAILURE';
export const GET_PROJECT_DETAIL_SUCCESS = 'cbre-pjm-principal-lite-project-new-project/GET_PROJECT_DETAIL_SUCCESS';
export const GET_PROJECT_DETAIL_FAILURE = 'cbre-pjm-principal-lite-project-new-project/GET_PROJECT_DETAIL_FAILURE';
export const GET_PROJECT_DETAIL_START = 'cbre-pjm-principal-lite-project-new-project/GET_PROJECT_DETAIL_START';
export const GET_PROJECT_DETAIL_RESET = 'cbre-pjm-principal-lite-project-new-project/GET_PROJECT_DETAIL_RESET';
export const EnterpriseCode = 'EA';
export const DivisionOffAccount = 'Off Account';
export const DivisionOnAccount = 'On Account';
export const PJMAppId5 = '5';
export const Sequential = 'Sequential';
export const sequential = 'sequential';
export const MODULE = 'cbre-pjm-principal-lite-project-new-project';
export const defaultBasicProjectInformationValues = {
  customerName: '',
  contract: '',
  clientLegalEntity: '',
  creditCheckResult: '',
  clientIndustry: '',
  buildingType: '',
  typeofEnquiry: '',
  rfpDueBy: null,
  estimatedProjectAwardDate: null,
  enquiryForm: '',
  formOfContractId: '',
  segment: '',
  addressRegion: '',
  addressSector: '',
  addressCountry: '',
  addressLine1: '',
  division: '',
  businessUnit: '',
  // instructionType: '',
  // procurementType: '',
  // msaType: '',
  cbreEntity: '',
  headOfProject: '',
  projectManager: '',
  managedServices: '',
  winningProbability: '',
  operatingModel: '',
  // projectType: '',
  pjmProjectType: '',
  netInternalArea: 0,
  currency: '',
  estimatedProjectValue: '',
  estimatedBidMarginFee: '',
  estimatedStartDate: null,
  estimatedCompletionDate: null,
  workOrderNumber: '',
  projectScope: '',
  pjmComments: '',
  isNotifiable: false,
  canSyncWithKahua: true,
  clientSector: '',
  projectSubType: '',
  // kahuaParentId: '',
  // kahuaTemplateId: '',
  // energyManager: '',
  // isEsgTeamInvolved: '',
  winningProbabilityNew: '',
};

export const basicProjectInformationFieldsOrder = {
  cbreStandard: {
    segment: 1,
    addressRegion: 2,
    addressRegion: 3,
    addressCountry: 4,
    division: 5,
    businessUnit: 6,
    instructionType: 7,
    procurementType: 8,
    cbreEntity: 9,
    headOfProject: 10,
    projectManager: 11,
    formOfContractId: 12,
  },
  customerDetails: {
    customerName: 11,
    contract: 12,
    clientLegalEntity: 13,
    creditCheckResult: 14,
    clientIndustry: 15,
    buildingType: 16,
    rfpDueBy: 17,
    estimatedProjectAwardDate: 18,
    clientSector: 19,
  },
  projectDetails: {
    managedServices: 20,
    winningProbability: 21,
    operatingModel: 22,
    pjmProjectType: 23,
    netInternalArea: 24,
    currency: 25,
    estimatedProjectValue: 26,
    estimatedBidMarginFee: 27,
    estimatedStartDate: 28,
    estimatedCompletionDate: 29,
    addressLine1: 30,
    projectScope: 31,
    pjmComments: 32,
    projectSubType: 33,
    energyManager: 34,
    isEsgTeamInvolved: 35,
    winningProbabilityNew: 36,
  },
};

export const clientApprovalsFieldsOrder = {
  estimatedStartDate: 1,
  estimatedCompletionDate: 2,
  estimatedProjectAwardDate: 3,
  cbreProjectNumber: 4,
  winningProbability: 5,
  approvalComments: 6,
};

export const defaultSummaryProjectInformationValues = {
  formOfContractId: '',
  enquiryForm: '',
  typeofEnquiry: '',
  contractType: '',
  performanceBond: '',
  parentCompanyGuarantee: '',
  designLiability: '',
  liquidatedDamages: '',
  defectsRectificationPeriod: '',
  retentionDetails: '',
  isRetentionRequired: '',
  retentionPercentage: '',
  retentionReleaseDate: '',
  valuationsIntervals: '',
  paymentMethod: '',
  insuranceRequirements: '',
  collateralWarranties: '',
  accountRebates: '',
  QHSEComments: '',
  legalComments: '',
  taxAssumptions: '',
  invoicingResponsibilityId: '',
  projectManagerOriginId: '',
  taxAssumptionIncludedInPricingId: '',
  taxDeptReviewCompleteId: '',
  programProjectId: '',
  deliveryTeamId: '',
  Risk: [],
  redOrYellowRiskComment: '',
  hasBudgetRiskMitigationPlan: '',
  budgetRiskAttachment: '',
  budgetRiskComments: '',
  isOpportunityRisk: '',
  winningProbabilityNew: '',
};

export const defaultClientApprovalValues = {
  approvalComments: '',
  estimatedStartDate: null,
  estimatedCompletionDate: null,
  estimatedProjectAwardDate: null,
  cbreProjectNumber: '',
  winningProbability: '',
};

export const summaryProjectInformationFieldsOrder = {
  formOfContractId: 1,
  enquiryForm: 2,
  typeofEnquiry: 3,
  contractType: 4,
  performanceBond: 5,
  parentCompanyGuarantee: 6,
  designLiability: 7,
  liquidatedDamages: 8,
  defectsRectificationPeriod: 9,
  retentionDetails: 10,
  valuationsIntervals: 11,
  paymentMethod: 12,
  insuranceRequirements: 13,
  collateralWarranties: 14,
  accountRebates: 15,
  QHSEComments: 16,
  legalComments: 17,
  hasBudgetRiskMitigationPlan: 18,
  budgetRiskAttachment: 19,
  budgetRiskComments: 20,
  redOrYellowRiskComment: 21,
  isOpportunityRisk: 22,
  winningProbabilityNew: 23,
};
export const tabValidationSequence = ['executivesummary', 'basicinfo', 'summary', 'costplan', 'approvals'];

export const workflowStatusMap = {
  submit: {
    id: ['PJMWF01', 'PJMWF04', 'PJMWF27', 'PJMWF28'],
    label: () => getMessage({ id: 'cbre-pjm-pds-form-root.button.submit' }),
  },
  recall: {
    id: ['PJMWF02'],
    label: () => getMessage({ id: 'cbre-pjm-pds-form-root.button.recall' }),
    key: 'recall',
  },
  unlock: {
    id: ['PJMWF03', 'PJMWF05', 'PJMWF06', 'PJMWF07', 'PJMWF08'],
    label: () => getMessage({ id: 'cbre-pjm-pds-form-root.button.unlock' }),
    key: 'unlock',
  },
  clientApprovalEditMode: {
    id: ['PJMWF01', 'PJMWF04', 'PJMWF03', 'PJMWF05', 'PJMWF06', 'PJMWF07', 'PJMWF08'],
    label: () => getMessage({ id: 'cbre-pjm-pds-form-root.button.unlock' }),
    key: 'None',
  },
};
export const defaultApprovalInformationValues = {
  approverMatrixId: '',
  contactId: '',
};

export const tabNameIdMapping = {
  executivesummary: 0,
  basicinfo: 1,
  summary: 2,
  costplan: 3,
  approvals: 4,
  reviewsubmit: 5,
  clientapproval: 6,
};

export const idTabNameMapping = {
  0: 'executivesummary',
  1: 'basicinfo',
  2: 'summary',
  3: 'costplan',
  4: 'approvals',
  5: 'reviewsubmit',
  6: 'clientapproval',
};

const checkOperatingModel = (currentModelUniqueCode) => {
  const allowedCodes = [
    //  LUC08FP = Principal - Fixed Price
    'LUC08FP',
    // LUC08GMP = Principal - Guranteed Maximum Price (GMP)
    'LUC08GMP',
    // LUC08RM = Principal - Remeasurable
    'LUC08RM',
  ];

  return allowedCodes.includes(currentModelUniqueCode);
};

export const isExecutedAgreementMatched = ({ operatingModelUniqueCode, isEA, isAD }) => {
  const allowedOperatingModel = checkOperatingModel(operatingModelUniqueCode);

  return allowedOperatingModel && (isEA || isAD);
};

export const boolToString = (bool) => {
  if (bool === true) {
    return 'yes';
  }
  if (bool === false) {
    return 'no';
  }
  return '';
};
