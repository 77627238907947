import { SET_LOADING_TRUE, SET_LOADING_FALSE, GET_COMMON_APPROVAL_HISTORY } from './constants';

const INITIAL_STATE = {
  approvalHistory: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_COMMON_APPROVAL_HISTORY:
      return {
        ...state,
        approvalHistory: action.payload,
      };
    default:
      return state;
  }
};
