export const OPEN_DELETE_PROJECT_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/OPEN_DELETE_PROJECT_DIALOG';
export const OPEN_DELETE_PROJECT_DIALOG_SUCCESS =
  'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/OPEN_DELETE_PROJECT_DIALOG_SUCCESS';
export const OPEN_DELETE_PROJECT_DIALOG_FAILURE =
  'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/OPEN_DELETE_PROJECT_DIALOG_FAILURE';
export const DELETE_PROJECT_START = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/DELETE_PROJECT_START';
export const DELETE_PROJECT_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/DELETE_PROJECT_FAILURE';
export const CLOSE_DELETE_PROJECT_TOAST = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/CLOSE_DELETE_PROJECT_TOAST';
export const CLOSE_DELETE_PROJECT_DIALOG = 'CBRE-PJM-CAPITAL-PLAN-DELETEPROJECT/CLOSE_DELETE_PROJECT_DIALOG';
