import React, { useCallback, useEffect, useState } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import { DARK_GREEN, GREEN, WHITE } from 'modules/cbre-pjm-Common/constants/ThemeColors';
import { setInitUrl } from 'modules/cbre-pjm-Common/actions';
import greenTheme from './themes/greenTheme';
import whiteTheme from './themes/whiteTheme';
import Fusion from './Fusion';
import AppLocale from '../lngProvider';
import AppLayout from './AppLayout';
import asyncComponent from '../util/asyncComponent';
import { FeatureFlagProvider } from './Services/FeatureFlagProvider';
import { AnalyticsProvider } from './Services/AnalyticsProvider';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store';
import AuthProvider from './Services/AuthProvider';
import SurveyBeacon from 'components/SurveyBeacon';

const getColorTheme = (themeColorName) => {
  let applyAppTheme;
  switch (themeColorName) {
    case GREEN: {
      applyAppTheme = createTheme(greenTheme);
      break;
    }
    case DARK_GREEN: {
      applyAppTheme = createTheme(greenTheme);
      break;
    }
    case WHITE: {
      applyAppTheme = createTheme(whiteTheme);
      break;
    }
    default:
      createTheme(greenTheme);
  }
  return applyAppTheme;
};

const getCurrentAppLocale = (locale, location) => {
  const showLanguageSelection = true; //location.pathname.indexOf('cpp') !== -1 || location.pathname.indexOf('pda') !== -1
  const isFusionApp = location.pathname.indexOf('fusion') !== -1;
  let currentAppLocale;
  if (isFusionApp) {
    currentAppLocale = AppLocale['en_US'];
  } else {
    currentAppLocale = AppLocale[showLanguageSelection ? locale.locale : 'en_US'];
  }
  return currentAppLocale;
};

const validateFusionRedirection = ({ authUser, encodedHref, props }) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    authUser === null &&
    props.history.location.pathname?.indexOf('fusion') === -1
  ) {
    if (window.location.href === window.location.origin || window.location.href === `${window.location.origin}/`) {
      window.location.assign(`/login?redirectUrl=${encodedHref}dashboard`);
    } else {
      window.location.assign(`/login?redirectUrl=${encodedHref}`);
    }
    return false;
  } else if (
    process.env.NODE_ENV === 'development' &&
    authUser === null &&
    props.history.location.pathname?.indexOf('fusion') === -1
  ) {
    window.location.assign(`http://localhost:3000/login?redirectUrl=${encodedHref}`);
    return false;
  } else {
    return true;
  }
};

const pageNameMappingForSurvey = {
  pda: 'pda',
  myprojects: 'myprojects',
  capitalplan: 'capitalplanner',
};

const allowedPageNamesForSurvey = ['pda', 'myprojects', 'capitalplan'];

const App = (props) => {
  const routerInfo = useSelector((state) => state.router);
  const clients = useSelector((state) => state.config);
  const getEnvKey = (key) => clients?.client?.[key] ?? '';

  const surveyBeaconKey = getEnvKey('surveyBeaconKey');
  const surveyBeaconPdaKey = getEnvKey('surveyBeaconPdaKey');
  const surveyBeaconCapitalPlannerKey = getEnvKey('surveyBeaconCapitalPlannerKey');
  const surveyBeaconMyProjectsKey = getEnvKey('surveyBeaconMyProjectsKey');

  const { uuid } = useSelector(({ auth }) => auth, shallowEqual);
  const dispatch = useDispatch();
  const [currentPageName, setCurrentPageName] = useState('');
  const [beaconKey, setBeaconKey] = useState('');
  // Get the page route from the URL
  const getPageRoute = () => {
    const pathname = routerInfo.location.pathname;
    const pathSegments = pathname.split('/').filter((segment) => segment !== '');
    return pathSegments.length > 0 ? pathSegments[0] : null;
  };

  useEffect(() => {
    const pageName = getPageRoute(routerInfo.location.pathname);
    setCurrentPageName(pageName);
  }, [routerInfo.location.pathname]);

  useEffect(() => {
    const beaconKeyMapping = {
      pda: surveyBeaconPdaKey,
      myprojects: surveyBeaconMyProjectsKey,
      capitalplan: surveyBeaconCapitalPlannerKey,
    };
    setBeaconKey(beaconKeyMapping[currentPageName] || surveyBeaconKey);
  }, [currentPageName]);

  // Get the page mapping key for survey beacon
  const app = useCallback(() => {
    return pageNameMappingForSurvey[currentPageName];
  }, [currentPageName]);

  const { themeColor, darkTheme, locale } = useSelector(({ settings }) => settings, shallowEqual);
  const { authUser, initURL } = useSelector(({ auth }) => auth, shallowEqual);
  const isDarkTheme = darkTheme;
  const { location } = props;
  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);

  let applyTheme;
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor);
    document.body.classList.add(themeColor);
  }
  const encodedHref = encodeURIComponent(window.location.href);
  const isLoginNotRequired = validateFusionRedirection({
    authUser,
    encodedHref,
    props,
  });
  if (!isLoginNotRequired) {
    return null;
  }
  applyTheme.direction = 'ltr';
  const currentAppLocale = getCurrentAppLocale(locale, location);

  return (
    <FeatureFlagProvider condition={false}>
      <AnalyticsProvider>
        <ThemeProvider theme={applyTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
              <div className="app-main">
                <ConnectedRouter history={history}>
                  <AuthProvider initURL={initURL}>
                    <Switch>
                      <Route path="/fusion" component={Fusion} />
                      <Route path="/fusion/:projectId" component={Fusion} />
                      <Route path="/maintenance" component={asyncComponent(() => import('app/Utils/maintenance'))} />
                      <Route authUser={authUser} component={AppLayout} />
                    </Switch>
                  </AuthProvider>
                </ConnectedRouter>
                <div id="popper-portal"></div>
              </div>
            </IntlProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
        {allowedPageNamesForSurvey.includes(currentPageName) && (
          <SurveyBeacon uuid={uuid} app={app()} surveyBeaconKey={beaconKey} />
        )}
      </AnalyticsProvider>
    </FeatureFlagProvider>
  );
};

export default App;
