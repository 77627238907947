/* eslint-disable */
import {
  SET_LOADING_TRUE,
  SET_SELECTED_JOB_ID,
  SET_SELECTED_ITEM_ID,
  GET_MIGRATION_LIST_START,
  GET_MIGRATION_LIST_FAILURE,
  GET_MIGRATION_LIST_SUCCESS,
  GET_MIGRATION_DETAILS_START,
  GET_MIGRATION_DETAILS_FAILURE,
  GET_MIGRATION_DETAILS_SUCCESS,
  GET_MIGRATION_COMPLETE_SUCCESS,
  GET_MIGRATION_COMPLETE_FAILURE,
  CLOSE_MIGRATION_COMPLETE_TOAST,
  GET_MIGRATION_STEP_START,
} from './constants';

const INITIAL_STATE = {
  loading: true,
  loadingSidebar: false,
  loadingDetails: false,
  importInitiated: false,
  totalRecords: 0,
  migrationsList: [],
  initialLoad: true,
  migrationSuccess: false,
  error: false,
  errorCode: '',
  alert: '',
  alertMessage: '',
  isMigrateBtnDisabled: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case GET_MIGRATION_LIST_START:
      return {
        ...state,
        loadingSidebar: true,
      };
    case GET_MIGRATION_LIST_SUCCESS:
      let { recordList, totalRecords } = action.payload;
      return {
        ...state,
        initialLoad: false,
        currentMigrationDetails: recordList && recordList.length === 0 ? {} : state.currentMigrationDetails,
        migrationsList: recordList,
        totalRecords,
        loadingSidebar: false,
      };
    case GET_MIGRATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loadingSidebar: false,
      };
    case GET_MIGRATION_DETAILS_START:
      return {
        ...state,
        loadingDetails: true,
      };
    case GET_MIGRATION_DETAILS_SUCCESS:
      // console.log('action.payload---', action.payload);
      return {
        ...state,
        loadingDetails: false,
        currentMigrationDetails: { ...action.payload },
      };
    case GET_MIGRATION_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_MIGRATION_STEP_START:
      return {
        ...state,
        isMigrateBtnDisabled: true,
        alert: 'information',
        alertMessage: 'Migration will continue',
      };
    case GET_MIGRATION_COMPLETE_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        currentMigrationDetails: { ...action.payload },
        migrationSuccess: true,
        alert: 'success',
        alertMessage: 'Migration Completed Successfully',
      };
    case GET_MIGRATION_COMPLETE_FAILURE:
      return {
        ...state,
        loadingDetails: false,
        currentMigrationDetails: { ...action.payload },
        migrationSuccess: false,
        alert: 'failure',
        alertMessage: 'Migration Failed !',
        isMigrateBtnDisabled: false,
      };
    case CLOSE_MIGRATION_COMPLETE_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        migrationStatus: '',
      };
    case SET_SELECTED_JOB_ID:
      return {
        ...state,
        selectedJobId: action.payload,
      };
    case SET_SELECTED_ITEM_ID:
      return {
        ...state,
        selectedItemId: action.payload,
      };
    default:
      return state;
  }
};
