/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Provider } from 'react-redux';

import configureStore, { history } from './store';
import App from './containers/App';
import envLocalVarState from './util/loadEnvVars';

let initialState =
  global.__INITIAL_STATE__ !== undefined && global.__INITIAL_STATE__ !== '__VARIABLE_INITIAL_STATE__'
    ? JSON.parse(JSON.stringify(global.__INITIAL_STATE__))
    : {};
initialState.wso2token =
  global.__INITIAL_FUSION_TOKEN__ !== undefined &&
  global.__INITIAL_FUSION_TOKEN__ !== '__VARIABLE_INITIAL_FUSION_TOKEN__'
    ? global.__INITIAL_FUSION_TOKEN__
    : '';
if (initialState.wso2token) {
  sessionStorage.setItem('wso2token', initialState.wso2token);
}
// eslint-disable-next-line eqeqeq
if (process.env.NODE_ENV == 'development') {
  initialState = envLocalVarState;
}

export const store = configureStore(initialState);

const MainApp = () => (
  // eslint-disable-next-line react/jsx-filename-extension
  <Provider store={store}>
    <App history={history} location={history.location} />
  </Provider>
);

// eslint-disable-next-line no-underscore-dangle
delete global.__INITIAL_STATE__;
const initialStateElement = document.querySelector('#initial-state');
if (initialStateElement && initialStateElement.parentNode) {
  initialStateElement.remove();
}

export default MainApp;
