/* eslint-disable */
import moment from 'moment';
import {
  GET_PLAN_START,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  GET_PLAN_RESET,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  ADD_NEW_PROJECT_START,
  ADD_NEW_PROJECT_SUCCESS,
  ADD_NEW_PROJECT_FAILURE,
  ADD_NEW_PROJECT_EXISTS_FAILURE,
  CLOSE_NEW_PROJECT_TOAST,
  RESET_NEW_PROJECT_STATUS,
  RESET_NEW_PROJECT_ERROR,
  RESET_FETCH_PROJECT_DETAILS,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_SUCCESS_PREVIEW,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  PUSH_PROJECT_TO_CLIENT_START,
  PUSH_PROJECT_TO_CLIENT_SUCCESS,
  PUSH_PROJECT_TO_CLIENT_FAILURE,
  GET_PROPERTY_SUCCESS,
} from './constants';
import {
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_FAILED,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_RESET,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_START,
  FUNDING_REQUEST_DETAILS_SAVE_UPDATE_SUCCESS,
} from '../../cbre-pjm-capital-plan-funding-request/ducks/constants';

const defaultCapitalPlanProject = {
  projectName: '',
  projectDescription: '',
  projectTypeSystem: '',
  dateNeededby: null,
  constructionStartDate: null,
  constructionCompletionDate: null,
  actualConstructionStart: null,
  actualConstructionCompletion: null,
  constructionDurationTotal: '',
  projectBudget: '',
  projectCosts: '',
  multiYearProject: '',
  criticality: '',
  projectRequestor: '',
  projectManager: '',
  projectStatus: '',
  clientProjectNumber: '',
  cashFlowCurveId: 5,
  justification: '',
  cbreTeamEngagement: [],
  pushProjectToClient: false,
  primaryTeam: '',
  leaseRenewalOnly: '',
  projectManagerId: null,
  directorId: null,
  facilityName: '',
  propertyId: '',
  facilityType: '',
  buildingType: '',
  facilityManager: '',
  leaseId: '',
  transactionManager: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  // county: "",
  zipCode: '',
  addressRegion: '',
  addressCountry: '',
  locationInFacility: '',
  leasedOwned: '',
  leaseRate: '',
  element: '',
  leaseEndDate: null,
  affectedArea: '',
  affectedPeopleCount: '',
  facilityLocation: null,
  fundingSource: '',
  probabilityOfImpact: '',
  magnitudeOfImpact: '',
  organizationalImpact: '',
  strategicAlignment: '',
  facilityConditionScore: '',
  experience: '',
  timingOfImpact: '',
  projectDriver: '',
  payback: '',
  customCriteria1: '',
  customCriteria2: '',
  customCriteria3: '',
  customCriteria4: '',
  customCriteria5: '',
  priorityScore: '',
  clientPriorityScore: '',
  syncStatus: null,
  retryCount: 0,
  capitalplanhdr: '',
  pjmSegmentId: '',
  currencyId: '',
  divisionId: '',
  approvedBy: '',
  propertyObj: null,
};
const INITIAL_STATE = {
  planHdrDetails: {},
  planCriteriaLookups: [],
  allLegacy: [],
  initialClientDetails: {},
  divisionLookup: [],
  simpleLookups: [],
  fetchLookupsError: false,
  loading: true,
  fetchLookupsStatus: '',
  createProjectStatus: '',
  createProjectError: '',
  messageCode: '',
  project: {},
  projectDetails: {},
  alert: '',
  isEdit: false,
  fetchProjectDetails: false,
  defaultCapitalPlanProject,
  capitalPlanProject: defaultCapitalPlanProject,
  capitalPlanProjectPreview: {},
  newProjectId: '',
  fundingRequestInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  propertyDetails: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PLAN_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        planHdrDetails: action.payload,
        // defaultCurrency: (action.payload.capitalPlanDetail?.currency) || {currency: 'USD'},
      };
    case GET_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_PLAN_RESET:
      return {
        ...state,
        loading: false,
        planHdrDetails: {},
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case ADD_NEW_PROJECT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case ADD_NEW_PROJECT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        project: action.payload.project,
        createProjectStatus: 'success',
        alert: 'success',
        isEdit: action.payload.isEdit,
        newProjectId: action.payload.newProjectId || '',
        createProjectError: '',
        messageCode: '',
      };
    case ADD_NEW_PROJECT_EXISTS_FAILURE:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        createProjectStatus: 'duplicate',
        createProjectError: true,
        ...action.payload,
      };
    case ADD_NEW_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        createProjectStatus: 'error',
        createProjectError: true,
        ...action.payload,
        // defaultValues: action.payload.plan
      };
    case PUSH_PROJECT_TO_CLIENT_START:
      return {
        ...state,
        // loading: true,
        ...action.payload,
      };
    case PUSH_PROJECT_TO_CLIENT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loading: false,
      };
    case PUSH_PROJECT_TO_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CLOSE_NEW_PROJECT_TOAST:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_NEW_PROJECT_STATUS:
      return {
        ...state,
        createProjectStatus: '',
        newProjectId: '',
      };
    case RESET_FETCH_PROJECT_DETAILS:
      return {
        ...state,
        fetchProjectDetails: false,
        capitalPlanProject: { ...defaultCapitalPlanProject },
        projectDetails: {},
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case RESET_NEW_PROJECT_ERROR:
      return {
        ...state,
        createProjectError: '',
        messageCode: '',
        createProjectStatus: '',
        newProjectId: '',
      };
    case GET_PROJECT_BY_ID_SUCCESS:
      const projectResponse = action.payload;
      const pjmSegmentId = projectResponse?.pjmSegmentId
        ? projectResponse.pjmSegmentId
        : projectResponse?.project?.projectPipelineHdr?.pjmSegmentId;
      const divisionId = projectResponse?.divisionId
        ? projectResponse.divisionId
        : projectResponse?.project?.projectPipelineHdr?.divisionId;
      try {
        const projectInfo = {
          projectName: projectResponse.project.projectTitle || '',
          projectDescription: projectResponse.project.projectDescription || '',
          projectTypeSystem:
            (projectResponse.project &&
              projectResponse.project.projectType &&
              projectResponse.project.projectType.projectTypeId) ||
            '',
          dateNeededby: projectResponse.dateNeeded,
          constructionStartDate: projectResponse.constructionStart,
          constructionCompletionDate:
            projectResponse.constructionCompletion === null
              ? projectResponse.constructionCompletion
              : moment(projectResponse.constructionCompletion, 'YYYY-MM-DD'),
          actualConstructionStart: projectResponse.project?.projectPipelineHdr?.actualConstructionStart
            ? moment(projectResponse.project?.projectPipelineHdr?.actualConstructionStart, 'YYYY-MM-DD')
            : null,
          actualConstructionCompletion: projectResponse.project?.projectPipelineHdr?.actualConstructionCompletion
            ? moment(projectResponse.project?.projectPipelineHdr?.actualConstructionCompletion, 'YYYY-MM-DD')
            : null,
          constructionDurationTotal: projectResponse.constructionDurationTotal || '',
          projectBudget: projectResponse.projectBudget,
          projectCosts: projectResponse.projectCosts,
          multiYearProject: projectResponse.multiyearProject,
          criticality: projectResponse.criticality || '',
          projectRequestor: projectResponse.projectRequestor || '',
          projectManager: projectResponse.projectManager || '',
          projectStatus: projectResponse.project.projectStatusId || '',
          clientProjectNumber: projectResponse.project.clientProjectNumber || '',
          cashFlowCurveId: projectResponse.cashFlowCurveId || '',
          justification: projectResponse.justification || '',
          cbreTeamEngagement: projectResponse?.project?.cbreTeamEngagement || projectResponse?.cbreTeamEngagement || [],
          pushProjectToClient: projectResponse?.project?.projectPipelineHdr?.pushProjectToClient || false,
          primaryTeam: projectResponse?.project?.primaryTeam || projectResponse?.primaryTeam || '',
          leaseRenewalOnly:
            typeof projectResponse?.project?.leaseRenewalOnly === 'boolean'
              ? projectResponse?.project?.leaseRenewalOnly
              : typeof projectResponse?.leaseRenewalOnly === 'boolean'
              ? projectResponse?.leaseRenewalOnly
              : '',
          projectManagerId:
            typeof projectResponse?.project?.projectManager === 'object'
              ? projectResponse?.project?.projectManager
              : typeof projectResponse?.projectManagerObj === 'object'
              ? projectResponse?.projectManagerObj
              : null,
          directorId: projectResponse?.project?.director || projectResponse?.director || null,

          facilityName: projectResponse.facilityName || '',
          propertyId: projectResponse.propertyId,
          facilityType: projectResponse.facilityType || '',
          buildingType:
            projectResponse.project.projectPipelineHdr?.buildingTypeId || projectResponse.project.buildingTypeId || '',
          facilityManager: projectResponse.facilityManager || '',
          leaseId: projectResponse?.project?.projectPipelineHdr?.leaseId || '',
          transactionManager: projectResponse?.project?.projectPipelineHdr?.transactionManager || '',
          addressLine1: projectResponse.project.address || '',
          addressLine2: projectResponse.project.addressLine2 || '',
          addressCity: projectResponse.project.cityName || '',
          facilityLocation: projectResponse.project.projectPipelineHdr?.pjmPropertyId || null,
          propertyObj: projectResponse.project.projectPipelineHdr?.property || null,
          addressState: projectResponse.project.state ? projectResponse.project.state.stateId : '',
          zipCode: projectResponse.project.zip || '',
          addressRegion: projectResponse.project.region.regionId || '',
          addressCountry: projectResponse.project?.country?.countryId || '',
          locationInFacility: projectResponse.locationInFacility || '',
          leasedOwned: projectResponse.leaseOwnedId || '',
          leaseRate: projectResponse.leaseRate,
          element: projectResponse.elementId || '',
          leaseEndDate: projectResponse.leaseEndDate,
          affectedArea: projectResponse.affectedArea,
          affectedPeopleCount: projectResponse.affectedPeopleCount,

          fundingSource: projectResponse.fundingSourceId || '',
          probabilityOfImpact: projectResponse.probabilityofImpactId || '',
          magnitudeOfImpact: projectResponse.magnitudeofImpactId || '',
          organizationalImpact: projectResponse.organizationalImpactId || '',
          strategicAlignment: projectResponse.strategicAlignmentId || '',
          facilityConditionScore: projectResponse.facilityConditionScoreId || '',
          experience: projectResponse.experienceId || '',
          timingOfImpact: projectResponse.timingofImpactId || '',
          projectDriver: projectResponse.projectDriverId || '',
          payback: projectResponse.paybackId || '',
          customCriteria1: projectResponse.customScore1Id || '',
          customCriteria2: projectResponse.customScore2Id || '',
          customCriteria3: projectResponse.customScore3Id || '',
          customCriteria4: projectResponse.customScore4Id || '',
          customCriteria5: projectResponse.customScore5Id || '',
          priorityScore: projectResponse.priorityScore || '',
          clientPriorityScore: projectResponse.clientPriorityScore || '',
          syncStatus: projectResponse.project.syncStatus || null,
          retryCount: projectResponse.project.retryCount || 0,
          capitalplanhdr: projectResponse.projectCapitalPlanHdrId || '',
          pjmSegmentId: pjmSegmentId || '',
          divisionId: divisionId || '',
          approvedBy: projectResponse?.approvedBy || '',
          currencyId: projectResponse?.currencyId || ''
        };
        return {
          ...state,
          capitalPlanProject: projectInfo,
          loading: false,
          fetchProjectDetails: true,
          projectDetails: action.payload || {},
        };
      } catch (err) {
        console.log('err---', err);
      }

    case GET_PROJECT_BY_ID_SUCCESS_PREVIEW:
      return {
        ...state,
        capitalPlanProjectPreview: action.payload,
        loading: false,
        fetchProjectDetails: true,
        projectDetails: action.payload || {},
      };

    case GET_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        propertyDetails: action.payload || [],
      };

    default:
      return state;
  }
};
