export const FORECAST_DETAILS_GET_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_GET_START';
export const FORECAST_DETAILS_GET_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_GET_SUCCESS';
export const FORECAST_DETAILS_GET_FAILED = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_GET_FAILED';
export const FORECAST_DETAILS_GET_RESET = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_GET_RESET';
export const FORECAST_DETAILS_SUBMIT_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_SUBMIT_START';
export const FORECAST_DETAILS_SUBMIT_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_SUBMIT_SUCCESS';
export const FORECAST_DETAILS_SUBMIT_FAILED = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_SUBMIT_FAILED';
export const FORECAST_DETAILS_SUBMIT_RESET = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_DETAILS_SUBMIT_RESET';
export const FORECAST_FINANCIAL_OVERVIEW_START =
  'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_FINANCIAL_OVERVIEW_START';
export const FORECAST_FINANCIAL_OVERVIEW_SUCCESS =
  'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_FINANCIAL_OVERVIEW_SUCCESS';
export const FORECAST_FINANCIAL_OVERVIEW_FAILED =
  'CBRE-PJM-PRINCIPAL-LITE-PROJECT-FORECAST/FORECAST_FINANCIAL_OVERVIEW_FAILED';
