export const SET_LOADING_TRUE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/SET_LOADING_FALSE';

export const GET_PDA_LIST_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_PDA_LIST_SUCCESS';
export const GET_PDA_LIST_FAILURE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_PDA_LIST_FAILURE';
export const GET_PDA_LIST_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_PDA_LIST_START';
export const GET_PDA_LIST_RESET = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_PDA_LIST_RESET';
export const GET_PDA_PROJECT_DETAILS_RESET = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_PDA_PROJECT_DETAILS_RESET';
export const GET_VENDOR_LIST_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_VENDOR_LIST_SUCCESS';
export const GET_VENDOR_LIST_FAILURE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_VENDOR_LIST_FAILURE';
export const GET_VENDOR_LIST_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_VENDOR_LIST_START';
export const GET_VENDOR_LIST_RESET = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_VENDOR_LIST_RESET';
export const GET_APPROVERS_LIST_SUCCESS = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_APPROVERS_LIST_SUCCESS';
export const GET_APPROVERS_LIST_FAILURE = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_APPROVERS_LIST_FAILURE';
export const GET_APPROVERS_LIST_START = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_APPROVERS_LIST_START';
export const GET_APPROVERS_LIST_RESET = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/GET_APPROVERS_LIST_RESET';
export const UPDATE_FEATURE_FLAG = 'CBRE-PJM-PRINCIPAL-LITE-PROJECT-ROOT/UPDATE_FEATURE_FLAG';
