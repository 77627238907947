export const subNavList = [
  { id: 0, name: 'costplan', label: 'J&A Cost Breakdown' },
  { id: 4, name: 'purchaserequests', label: 'Purchase Request' },
  { id: 1, name: 'costchangerequests', label: 'Cost Change Requests' },
  { id: 2, name: 'purchaseorders', label: 'Purchase Orders' },
  { id: 3, name: 'vendorinvoices', label: 'Vendor Invoices' },
];

export const idSubTabNameMapping = {
  0: 'costplan',
  1: 'purchaseorders',
};
export const SET_LOADING_TRUE = 'cbre-pjm-principal-lite-project-cost-plan/SET_LOADING_TRUE';
export const SET_LOADING_FALSE = 'cbre-pjm-principal-lite-project-cost-plan/SET_LOADING_FALSE';

export const GET_VENDOR_LIST_SUCCESS = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_SUCCESS';
export const GET_VENDOR_LIST_FAILURE = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_FAILURE';
export const GET_VENDOR_LIST_START = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_START';
export const GET_VENDOR_LIST_RESET = 'CBRE-PJM-PDA-FORM-ROOT/GET_VENDOR_LIST_RESET';

export const COST_PLAN_DELETE_START = 'COST_PLAN_DELETE_START';
export const COST_PLAN_DELETE_SUCCESS = 'COST_PLAN_DELETE_SUCCESS';
export const COST_PLAN_DELETE_FAILURE = 'COST_PLAN_DELETE_FAILURE';
export const COST_PLAN_DELETE_RESET = 'COST_PLAN_DELETE_RESET';
export const COST_PLAN_STATE_UPDATE = 'COST_PLAN_STATE_UPDATE';
export const COST_PLAN_OBJ_REQUESTED = 'COST_PLAN_OBJ_REQUESTED';

export const WORKPACKAGE_ADD_FAILURE = 'WORKPACKAGE_ADD_FAILURE';
export const WORKPACKAGE_ADD_START = 'WORKPACKAGE_ADD_START';
export const WORKPACKAGE_ADD_SUCCESS = 'WORKPACKAGE_ADD_SUCCESS';
export const WORKPACKAGE_ADD_RESET = 'WORKPACKAGE_ADD_RESET';

export const GET_DEFAULT_WORKPACKAGE_START = 'GET_DEFAULT_WORKPACKAGE_START';
export const GET_DEFAULT_WORKPACKAGE_SUCCESS = 'GET_DEFAULT_WORKPACKAGE_SUCCESS';
export const GET_DEFAULT_WORKPACKAGE_FAILURE = 'GET_DEFAULT_WORKPACKAGE_FAILURE';

export const GET_PURCHASE_ORDERS_SUCCESS = 'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_ORDERS_SUCCESS';
export const GET_PURCHASE_ORDERS_FAILURE = 'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_ORDERS_FAILURE';

export const GET_VENDOR_INVOICES_SUCCESS = 'cbre-pjm-principal-lite-project-cost-plan/GET_VENDOR_INVOICES_SUCCESS';
export const GET_VENDOR_INVOICES_FAILURE = 'cbre-pjm-principal-lite-project-cost-plan/GET_VENDOR_INVOICES_FAILURE';

export const GET_PURCHASE_REQUEST_START = 'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_START';
export const GET_PURCHASE_REQUEST_SUCCESS = 'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_SUCCESS';
export const GET_PURCHASE_REQUEST_FAILURE = 'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_FAILURE';

export const GET_PURCHASE_REQUEST_DETAILS_START =
  'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_DETAILS_START';
export const GET_PURCHASE_REQUEST_DETAILS_RESET =
  'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_DETAILS_RESET';
export const GET_PURCHASE_REQUEST_DETAILS_SUCCESS =
  'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_DETAILS_SUCCESS';
export const GET_PURCHASE_REQUEST_DETAILS_FAILURE =
  'cbre-pjm-principal-lite-project-cost-plan/GET_PURCHASE_REQUEST_DETAILS_FAILURE';
export const GET_ALL_LOOKUPS_SUCCESS = 'cbre-pjm-principal-lite-project-cost-plan/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'cbre-pjm-principal-lite-project-cost-plan/GET_ALL_LOOKUPS_FAILURE';

export const GET_CHANGE_REQUEST_START = 'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_START';
export const GET_CHANGE_REQUEST_SUCCESS = 'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_SUCCESS';
export const GET_CHANGE_REQUEST_FAILURE = 'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_FAILURE';
export const GET_LAST_CHANGE_REQUEST_DETAIL_START =
  'cbre-pjm-principal-lite-project-cost-plan/GET_LAST_CHANGE_REQUEST_DETAIL_START';
export const GET_LAST_CHANGE_REQUEST_DETAIL_SUCCESS =
  'cbre-pjm-principal-lite-project-cost-plan/GET_LAST_CHANGE_REQUEST_DETAIL_SUCCESS';
export const GET_LAST_CHANGE_REQUEST_DETAIL_FAILURE =
  'cbre-pjm-principal-lite-project-cost-plan/GET_LAST_CHANGE_REQUEST_DETAIL_FAILURE';

export const GET_CHANGE_REQUEST_DETAIL_START =
  'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_DETAIL_START';
export const GET_CHANGE_REQUEST_DETAIL_RESET =
  'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_DETAIL_RESET';
export const GET_CHANGE_REQUEST_DETAIL_SUCCESS =
  'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_DETAIL_SUCCESS';
export const GET_CHANGE_REQUEST_DETAIL_FAILURE =
  'cbre-pjm-principal-lite-project-cost-plan/GET_CHANGE_REQUEST_DETAIL_FAILURE';
export const MYBUY_ACTION_START = 'MYBUY_ACTION_START';
export const MYBUY_ACTION_SUCCESS = 'MYBUY_ACTION_SUCCESS';
export const MYBUY_ACTION_FAILURE = 'MYBUY_ACTION_FAILURE';
export const BUDGET_VALIDATION_ERROR = 'BUDGET_VALIDATION_ERROR';
