/* eslint-disable */
import {
  GET_PLAN_START,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  GET_PLAN_RESET,
  GET_FILTER_SUCCESS,
  GET_FILTER_FAILURE,
  GET_FILTER_RESET,
  GET_TOP10_PROJECTS_SUCCESS,
  GET_TOP10_PROJECTS_FAILURE,
  GET_TOP10_PROJECTS_RESET,
  SAVE_PROJECT_PRIORITY_SCORE_SUCCESS,
  SAVE_PROJECT_PRIORITY_SCORE_FAILURE,
  GET_CASH_FLOW_GRAPH_DATA_SUCCESS,
  GET_CASH_FLOW_GRAPH_DATA_RESET,
  GET_CASH_FLOW_GRAPH_DATA_FAILURE,
  GET_PLAN_LIST,
  GET_PLAN_LIST_FAILURE,
  GET_PLAN_LIST_SUCCESS,
  SET_CHECKED_PROJECT_LIST,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_PROJECT_LIST_FAILURE,
  GET_PROJECT_LIST_SUCCESS,
  GET_PROJECT_LIST,
  GET_PROJECT_COLUMNS,
  GET_PROJECT_COLUMNS_FAILURE,
  GET_PROJECT_COLUMNS_SUCCESS,
  GET_MANAGE_COLUMNS,
  GET_MANAGE_COLUMNS_FAILURE,
  GET_MANAGE_COLUMNS_SUCCESS,
  PUSH_TO_KAHUA_SUCCESS,
  PUSH_TO_KAHUA_FAILURE,
  PUSH_TO_KAHUA_START,
  PUSH_TO_KAHUA_RESET,
  GET_FROM_KAHUA_SUCCESS,
  GET_FROM_KAHUA_FAILURE,
  GET_FROM_KAHUA_START,
  GET_FROM_KAHUA_RESET,
  UPDATE_FROM_KAHUA_SUCCESS,
  UPDATE_FROM_KAHUA_FAILURE,
  UPDATE_FROM_KAHUA_START,
  UPDATE_FROM_KAHUA_RESET,
  POLLING_API_START,
  POLLING_API_SUCCESS,
  POLLING_API_FAILURE,
  POLLING_API_RESET,
  UPDATE_CP_TAB_COUNT,
  GET_ALL_SIMPLE_LOOKUPS_SUCCESS,
  GET_ALL_SIMPLE_LOOKUPS_FAILURE,
  UPDATE_CP_MILESTONE_TAB_COUNT,
  GET_ALL_CONTACT_LOOKUPS_SUCCESS,
  GET_ALL_CONTACT_LOOKUPS_FAILURE,
  RESET_COLUMNS,
} from './constants';

const INITIAL_STATE = {
  planHdrDetails: {},
  filterHdrDetails: {},
  top10Projects: {},
  savePriorityScore: {},
  saveRenderCount: 0,
  error: false,
  errorCode: '',
  loading: false,
  cashFlowData: [],
  planList: [],
  checkedProjectList: [],
  pushToKahuaStatus: '',
  pushToKahuaLoading: false,
  getFromKahuaStatus: '',
  getFromKahuaLoading: false,
  alert: '',
  alertMessage: '',
  handleAlertClose: '',
  showPullKahuaSuccessToaster: false,
  showPullKahuaFailureToaster: false,
  columnList: { clientColumns: [], userColumns: [] },
  manageColumnList: { clientMilestoneColumns: [], userMilestoneColumns: [] },
  kahuaPullStatus: {},
  getStatusUpdate: '',
  refreshUrl: { shouldRefresh: false, selectedTabIndex: 0 },
  pollingKahuaSyncStatus: { isPolling: false, status: null },
  allLookup: [],
  allLegacy: [],
  allContacts: [],
  projectListCount: 0,
  projectMilestoneCount: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PLAN_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case UPDATE_CP_TAB_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CP_MILESTONE_TAB_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        planHdrDetails: action.payload,
      };
    case GET_PLAN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };

    case GET_PROJECT_COLUMNS:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_PROJECT_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        columnList: action.payload,
      };
    case GET_PROJECT_COLUMNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
        columnList: { clientColumns: [], userColumns: [] },
        manageColumnList: { clientMilestoneColumns: [], userMilestoneColumns: [] },
      };
    case GET_MANAGE_COLUMNS:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case RESET_COLUMNS:
      return {
        ...state,
        columnList: { clientColumns: [], userColumns: [] },
        manageColumnList: { clientMilestoneColumns: [], userMilestoneColumns: [] },
      };
    case GET_MANAGE_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        manageColumnList: action.payload,
      };
    case GET_MANAGE_COLUMNS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_PLAN_RESET:
      return {
        ...state,
        loading: false,
        planHdrDetails: {},
      };
    case GET_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        filterHdrDetails: action.payload,
      };
    case GET_FILTER_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        errorCode: action.payload,
      };
    case GET_FILTER_RESET:
      return {
        ...state,
        loading: false,
        filterHdrDetails: {},
      };
    case GET_TOP10_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        top10Projects: action.payload,
      };
    case GET_TOP10_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_TOP10_PROJECTS_RESET:
      return {
        ...state,
        loading: false,
        top10Projects: {},
      };
    case SAVE_PROJECT_PRIORITY_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        savePriorityScore: action.payload,
        saveRenderCount: state.saveRenderCount + 1,
      };
    case SAVE_PROJECT_PRIORITY_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_CASH_FLOW_GRAPH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        cashFlowData: action.payload,
      };
    case GET_CASH_FLOW_GRAPH_DATA_RESET:
      return {
        ...state,
        loading: false,
        cashFlowData: [],
      };
    case GET_CASH_FLOW_GRAPH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_PLAN_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_PLAN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        planList: action.payload,
      };
    case GET_PLAN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_PROJECT_LIST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        planList: action.payload,
      };
    case GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case SET_CHECKED_PROJECT_LIST:
      return {
        ...state,
        checkedProjectList: action.payload,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_CONTACT_LOOKUPS_SUCCESS:
      return {
        ...state,
        allContacts: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_CONTACT_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case GET_ALL_SIMPLE_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLookup: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_SIMPLE_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };

    /* ----------------------Push to Kahua API ---------------------------------------------------- */

    case PUSH_TO_KAHUA_START:
      return {
        ...state,
        pushToKahuaLoading: true,
      };
    case PUSH_TO_KAHUA_SUCCESS:
      return {
        ...state,
        pushToKahuaLoading: false,
        pushToKahuaStatus: 'success',
      };
    case PUSH_TO_KAHUA_FAILURE:
      return {
        ...state,
        pushToKahuaLoading: false,
        pushToKahuaStatus: 'failure',
      };
    case PUSH_TO_KAHUA_RESET:
      return {
        ...state,
        pushToKahuaStatus: '',
      };

    /* -------------------------------Get From Kahua  ------------------------------------------- */
    case GET_FROM_KAHUA_START:
      return {
        ...state,
        getFromKahuaLoading: true,
      };
    case GET_FROM_KAHUA_SUCCESS:
      return {
        ...state,
        getFromKahuaLoading: false,
        getFromKahuaStatus: 'success',
        loading: false,
      };
    case GET_FROM_KAHUA_FAILURE:
      return {
        ...state,
        getFromKahuaLoading: false,
        getFromKahuaStatus: 'failure',
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_FROM_KAHUA_RESET:
      return {
        ...state,
        getFromKahuaStatus: '',
      };

    /* -----------------------------Get Update and status --------------------------------------------- */

    case UPDATE_FROM_KAHUA_START:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_FROM_KAHUA_SUCCESS:
      return {
        ...state,
        loading: false,
        getStatusUpdate: 'success',
        kahuaPullStatus: action.payload,
      };
    case UPDATE_FROM_KAHUA_FAILURE:
      return {
        ...state,
        loading: false,
        getStatusUpdate: 'failure',
        error: true,
        errorCode: action.payload,
        kahuaPullStatus: {},
      };
    case UPDATE_FROM_KAHUA_RESET:
      return {
        ...state,
        kahuaPullStatus: {},
        getStatusUpdate: '',
      };

    case POLLING_API_START:
      return {
        ...state,
        pollingKahuaSyncStatus: action.payload,
      };
    case POLLING_API_SUCCESS:
      return {
        ...state,
        pollingKahuaSyncStatus: action.payload,
      };
    case POLLING_API_FAILURE:
      return {
        ...state,
        pollingKahuaSyncStatus: action.payload,
      };
    case POLLING_API_RESET:
      return {
        ...state,
        pollingKahuaSyncStatus: { isPolling: true, status: null },
      };
    default:
      return state;
  }
};
