/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import UserInfo from 'components/UserInfo';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'modules/cbre-pjm-Common/constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'modules/cbre-pjm-Common/actions/Setting';
import SideBarContent from './SideBarContent';

const SideBar = (props) => {
  const dispatch = useDispatch();
  const { drawerType, width, navigationStyle } = useSelector(({ settings }) => settings);
  const [navCollapsed, setNavCollapsed] = useState(false);
  const { isNavCollapsed } = props;

  /*  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth))
    });
  }, [dispatch]); */

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
    // setNavCollapsed(!navCollapsed);
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'd-xl-flex'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? ''
    : 'd-flex';
  let type = 'permanent';
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && window.innerWidth < 1200)) {
    console.log(`${isNavCollapsed}isNavCollapsed navCollapsed from sideBar - ${navCollapsed}`);
    type = 'temporary';
  }

  return (
    <div className={`app-sidebar d-none ${drawerStyle}`}>
      <Drawer
        className="app-sidebar-content"
        variant={type}
        open={type.includes('temporary') ? isNavCollapsed : true}
        onClose={onToggleCollapsedNav}
        classes={{
          paper: 'side-nav top-71',
        }}
      >
        {/* <UserInfo/> */}
        <SideBarContent />
      </Drawer>
    </div>
  );
};

export default withRouter(SideBar);
