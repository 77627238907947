import { ConfirmationModal } from 'pjm-dls';
import IntlMessages from 'util/IntlMessages';

const PromptOnOrgChange = ({ confirmedNavigation, setConfirmedNavigation, handleCancel }) => {
  const onNavigationCloseConf = () => {
    setConfirmedNavigation(false);
  };

  return (
    <ConfirmationModal
      message={<IntlMessages id="cbre-pjm-pds-form-root.leavePage" />}
      open={confirmedNavigation}
      fcName="fc-black"
      onCloseModal={handleCancel}
      onConfirmAction={onNavigationCloseConf}
    />
  );
};

export default PromptOnOrgChange;
