export const GET_ACTIONS_PROJECTS_SUCCESS = 'GET_ACTIONS_PROJECTS_SUCCESS';
export const GET_ACTIONS_PROJECTS_FAILURE = 'GET_ACTIONS_PROJECTS_FAILURE';
export const GET_ACTIONS_PROJECT_DETAILS_START = 'GET_ACTIONS_PROJECT_DETAILS_START';
export const GET_ACTIONS_PROJECT_DETAILS_SUCCESS = 'GET_ACTIONS_PROJECT_DETAILS_SUCCESS';
export const GET_ACTIONS_PROJECT_DETAILS_FAILURE = 'GET_ACTIONS_PROJECT_DETAILS_FAILURE';
export const GET_ACTIONS_PROJECTS_START = 'GET_ACTIONS_PROJECTS_START';
export const UPDATE_USER_ACCESS_STATUS_START = 'UPDATE_USER_ACCESS_STATUS_START';
export const UPDATE_USER_ACCESS_STATUS_SUCCESS = 'UPDATE_USER_ACCESS_STATUS_SUCCESS';
export const UPDATE_USER_ACCESS_STATUS_FAILURE = 'UPDATE_USER_ACCESS_STATUS_FAILURE';
export const UPDATE_COMMENTS_START = 'UPDATE_COMMENTS_START';
export const UPDATE_COMMENTS_SUCCESS = 'UPDATE_COMMENTS_SUCCESS';
export const UPDATE_COMMENTS_FAILURE = 'UPDATE_COMMENTS_FAILURE';
export const CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST = 'CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST';
export const IS_ACTIVE_ITEM_SET_TO_INITIAL = 'IS_ACTIVE_ITEM_SET_TO_INITIAL';
