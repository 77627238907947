export const OPEN_EMAIL_AUTHENTICATION_DIALOG =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/OPEN_EMAIL_AUTHENTICATION_DIALOG';
export const CLOSE_EMAIL_AUTHENTICATION_DIALOG =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_EMAIL_AUTHENTICATION_DIALOG';
export const RESET_EMAIL_AUTHENTICATION = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/RESET_EMAIL_AUTHENTICATION';

export const DISABLE_EMAIL_AUTHENTICATION =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DISABLE_EMAIL_AUTHENTICATION';
export const START_EMAIL_AUTHENTICATION = 'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/START_EMAIL_AUTHENTICATION';

export const SUCCESS_EMAIL_AUTHENTICATION =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/SUCCESS_EMAIL_AUTHENTICATION';
export const FAILURE_EMAIL_AUTHENTICATION =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/FAILURE_EMAIL_AUTHENTICATION';
export const DUPLICATE_EMAIL_AUTHENTICATION =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/DUPLICATE_EMAIL_AUTHENTICATION';
export const CLOSE_TOAST_EMAIL_AUTHENTICATION =
  'CBRE-PJM-PROJECT-TRACKER-NEW-PROJECT-ADD-USER/CLOSE_TOAST_EMAIL_AUTHENTICATION';
