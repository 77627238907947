/* eslint-disable complexity */
import { GET_DOCUMENT_START, GET_DOCUMENT_SUCCESS, GET_DOCUMENT_FAILURE, GET_DOCUMENT_RESET } from './constants';

const INITIAL_STATE = {
  loading: false,
  error: false,
  messageCode: '',
  alert: '',
  alertMessage: '',
  documentInfo: {
    message: '',
    messageCode: '',
    loading: false,
  },
  documentSectionsData: [],
  documentNavSectionsData: [],
};

const documentReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_DOCUMENT_START:
      return {
        ...state,
        documentInfo: {
          message: '',
          messageCode: '',
          loading: true,
        },
        loading: true,
      };
    case GET_DOCUMENT_RESET:
      return {
        ...state,
        documentInfo: {
          message: '',
          messageCode: '',
          loading: false,
        },
      };
    case GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        documentInfo: {
          message: 'success',
          loading: false,
          messageCode: '',
        },
        documentSectionsData: action.payload.sections,
        documentNavSectionsData: action.payload.navSections,
        loading: false,
      };
    case GET_DOCUMENT_FAILURE:
      return {
        ...state,
        documentInfo: {
          response: action.payload,
          loading: false,
        },
        loading: false,
      };
    default:
      return state;
  }
};
export default documentReducer;
