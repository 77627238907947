export const GET_DISTRIBUTION_LIST_LOOKUPS_SUCCESS =
  'cbre-pjm-cpp-basic-project-info/GET_DISTRIBUTION_LIST_LOOKUPS_SUCCESS';
export const GET_DISTRIBUTION_LIST_LOOKUPS_FAILURE =
  'cbre-pjm-cpp-basic-project-info/GET_DISTRIBUTION_LIST_LOOKUPS_FAILURE';
export const GET_DISTRIBUTION_LIST_LOOKUPS_START =
  'cbre-pjm-cpp-basic-project-info/GET_DISTRIBUTION_LIST_LOOKUPS_START';

export const SAVE_CPP_PROJECT_DETAILS_SUCCESS = 'cbre-pjm-cpp-basic-project-info/SAVE_CPP_PROJECT_DETAILS_SUCCESS';
export const SAVE_CPP_PROJECT_DETAILS_FAILURE = 'cbre-pjm-cpp-basic-project-info/SAVE_CPP_PROJECT_DETAILS_FAILURE';
export const SAVE_CPP_PROJECT_DETAILS_START = 'cbre-pjm-cpp-basic-project-info/SAVE_CPP_PROJECT_DETAILS_START';
export const SAVE_CPP_PROJECT_DETAILS_RESET = 'cbre-pjm-cpp-basic-project-info/SAVE_CPP_PROJECT_DETAILS_RESET';

export const GET_USER_LIST_LOOKUPS_SUCCESS = 'cbre-pjm-cpp-basic-project-info/GET_USER_LIST_LOOKUPS_SUCCESS';
export const GET_USER_LIST_LOOKUPS_FAILURE = 'cbre-pjm-cpp-basic-project-info/GET_USER_LIST_LOOKUPS_FAILURE';
export const GET_USER_LIST_LOOKUPS_START = 'cbre-pjm-cpp-basic-project-info/GET_USER_LIST_LOOKUPS_START';

export const GET_CUSTOMER_LOOKUPS_SUCCESS = 'cbre-pjm-cpp-basic-project-info/GET_CUSTOMER_LOOKUPS_SUCCESS';
export const GET_CUSTOMER_LOOKUPS_FAILURE = 'cbre-pjm-cpp-basic-project-info/GET_CUSTOMER_LOOKUPS_FAILURE';
export const GET_CUSTOMER_LOOKUPS_START = 'cbre-pjm-cpp-basic-project-info/GET_CUSTOMER_LOOKUPS_START';
