/* eslint-disable */
import {
  OPEN_CREATE_PROGRAM_DIALOG,
  CLOSE_CREATE_PROGRAM_DIALOG,
  DISABLE_CREATE_PROGRAM,
  RESET_CREATE_PROGRAM,
  START_CREATE_PROGRAM,
  FAILURE_CREATE_PROGRAM,
  SUCCESS_CREATE_PROGRAM,
  DUPLICATE_CREATE_PROGRAM,
  CLOSE_TOAST_CREATE_PROGRAM,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  OPEN_EDIT_PROGRAM_DIALOG_SUCCESS,
  OPEN_EDIT_PROGRAM_DIALOG_FAILURE,
} from './constants';

const INITIAL_STATE = {
  open: false,
  loading: false,
  error: false,
  addUserSuccess: false,
  currentPath: '',
  editedValues: {},
  messageCode: '',
  alert: '',
  alertMessage: '',
  programAlert: false,
  allLegacy: {},
  mode: '',
  editDialogOpen: false,
  client: 0,
  planId: 0,
  status: '',
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OPEN_CREATE_PROGRAM_DIALOG:
      return {
        ...state,
        open: true,
        editedValues: action.payload.editedValues,
        mode: 'add',
        currentPath: action.payload.pathName,
      };
    case CLOSE_CREATE_PROGRAM_DIALOG:
      return {
        ...state,
        open: false,
        mode: '',
        client: 0,
        ...action.payload,
        currentPath: '',
      };
    case DISABLE_CREATE_PROGRAM:
      return {
        ...state,
        open: false,
      };
    case RESET_CREATE_PROGRAM:
      return {
        ...state,
        error: false,
        messageCode: '',
        addUserSuccess: false,
        currentPath: '',
      };
    case START_CREATE_PROGRAM:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };

    case SUCCESS_CREATE_PROGRAM:
      return {
        ...state,
        ...action.payload,
        loading: false,
        status: 'success',
      };
    case FAILURE_CREATE_PROGRAM:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,
      };
    case DUPLICATE_CREATE_PROGRAM:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,
      };
    case CLOSE_TOAST_CREATE_PROGRAM:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        programAlert: false,
        ...action.payload,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case OPEN_EDIT_PROGRAM_DIALOG_SUCCESS:
      return {
        ...state,
        open: true,
        currentPath: action.payload?.openedName,
        mode: 'edit',
        editedValues: action.payload.data,
        client: action.payload.client || 0,
        editDialogOpen: true,
        loading: false,
        status: '',
      };
    case OPEN_EDIT_PROGRAM_DIALOG_FAILURE:
      return {
        ...state,
        open: false,
        currentPath: '',
        mode: '',
        loading: false,
      };

    default:
      return state;
  }
};
