/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { COLLAPSED_DRAWER, FIXED_DRAWER, MINI_DRAWER } from 'modules/cbre-pjm-Common/constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import Header from './Header';
import SideBar from '../../SideBar';
import IntlMessages from 'util/IntlMessages';
import { Alerts } from 'pjm-dls';

const Vertical = (props) => {
  const { drawerType, saveLanguage } = useSelector(({ settings }) => settings);
  const [toasterState, setToasterState] = useState(false);
  const [toasterMessage, setToasterMessage] = useState('');
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'fixed-drawer'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'collapsible-drawer'
    : 'mini-drawer';

  // set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height');
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height');
  }

  useEffect(() => {
    if (saveLanguage?.message === 'success') {
      setToasterState('success');
      setToasterMessage(<IntlMessages id="languageSave.success" />);
    }
    if (saveLanguage?.message === 'failure') {
      setToasterState('failure');
      setToasterMessage(<IntlMessages id="languageSave.error" />);
    }
  }, [saveLanguage?.message]);

  const handleAlertClose = () => {
    setToasterState(false);
    setToasterMessage('');
  };

  return (
    <div className={`app-container ${drawerStyle}`}>
      {!props.hideMenu &&
      (drawerType.includes(MINI_DRAWER) || (drawerType.includes(FIXED_DRAWER) && window.innerWidth >= 1200)) ? (
        <SideBar />
      ) : (
        ''
      )}
      <div className="app-main-container">
        <div className="app-header">
          <Header hideMenu={props.hideMenu} isFusion={props.isFusion} />
        </div>

        <div className="app-main-content-wrapper">
          <div className="app-main-content">{props.children}</div>
        </div>
      </div>
      <Alerts alertState={toasterState} handleRequestClose={handleAlertClose} message={toasterMessage} />
    </div>
  );
};

export default withRouter(Vertical);
