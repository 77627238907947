/* eslint-disable */
import { createReducer, createSlice } from '@reduxjs/toolkit';
import spacePlanDefaultData from '../components/SpacePlanDefaultData';
import spacePlanDefaultDataSQM from '../components/SpacePlanDefaultDataSQM';
import { getSpacePlanData } from '../components/SpacePlanUtils';

const INITIAL_STATE = {
  spaceMockData: [],
  spaceDefaultData: [],
  projectDetails: { spacePlanHdr: {} },
  showAlert: false,
  openErrorModal: false,
  type: '',
  alertMessage: '',
  validationErrorMessage: '',
  labelErrorId: '',
  isMandatoryValid: true,
  mandatoryErrorList: {},
  fcName: 'fc-red',
  bannerClass: 'hideRentableAreaBanner',
  rentableAreaChanged: false,
  selectedCurrency: 'USD',
  selectedUnit: 'SQ FT',
  spacePlanDefaultData,
  currentUnit: 'SQ FT',
  projectId: 0,
  totalCost: 0,
  clientId: '',
  openExportModal: false,
  deviceOrientation: '',
  confirmationModalMessage: '',
  openConfirmationModal: false,
  dataLoaded: false,
  loading: false,
  checkBlock: false,
  costEstimateTotaFirstSection: {},
  luData: '',
  lastUpdatedDt: '',
  costEstimateDataLoaded: false,

  costEstimate: {
    costEstimateAdjustment: [],
    costAdjustmentRowObject: [],

    costEstimateScope: [],
    costScopeRowObject: [], //TODO: CAn be used for Summary Section

    costEstimateAdditional: [],
    costAdditionalRowObject: [],

    costEstimateUserAdded: [],
    costUserAddedRowObject: [],
  },
  costAdjustmentRowObjectDEF: [],
  costScopeRowObjectDEF: [],
  costAdditionalRowObjectDEF: [],
  costUserAddedRowObjectDEF: [],
  initialTotalCost: 0,
  totalUOM: 0,
  userRoles: [],
  openErrModal: false,
  openSaveModal: false,
  validationErrMessage: '',
  spaceMockDataDEF: [],
  projectDetailsDEF: { spacePlanHdr: {} },
  spaceDefaultDataDEF: [],
  open: false,
  message: '',
  navigationPath: '',
  budgetSummary: {},
  budgetBreakdown: {},
  compoundingPeriod: '',
  scheduleChartData: [],
  scheduleChartDataDEF: [],
  createProjectFlag: false,
  showHideMenu: true,
  nextFormObject: [],
  checkAddForChangesActionFlag: false,
  pathPram: '',
  projectStartDate: '',
  duration: '',
  ProjectClientId: '',
  logoutAction: '',
  contentLoading: false,
};

const spacePlanSlice = createSlice({
  name: 'EstimatorSpacePlan',
  initialState: INITIAL_STATE,
  reducers: {
    getEstimatorSpacePlanReset(state) {
      return {
        ...state,
        ...INITIAL_STATE,
      };
    },
    getEstimatorSpacePlanStart(state) {
      return {
        ...state,
        loading: true,
      };
    },
    updateEstimatorSpacePlanDetailsState(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    getEstimatorSpacePlanSuccess(state, action) {
      const responseData = action.payload;
      const originalTotalAreaRsf = responseData.spacePlanHdr.totalAreaRentable;
      const data = getSpacePlanData(responseData);
      const collaborativeSpaceItems = responseData.spacePlanLns.filter(
        (item) => item.spaceName === 'Collaborative Space',
      );
      const commonAreaSpaceItems = responseData.spacePlanLns.filter((item) => item.spaceName === 'Common Areas');
      responseData.spacePlanHdr.totalAreaRentable = originalTotalAreaRsf;
      return {
        ...state,
        loading: false,
        dataLoaded: true,
        spacePlanDefaultData: responseData.unit.unit === 'SQ MT' ? spacePlanDefaultDataSQM : spacePlanDefaultData,
        spaceMockData: data[0],
        collaborativeSpaceItem: JSON.parse(JSON.stringify(collaborativeSpaceItems[0])),
        commonAreaSpaceItem: JSON.parse(JSON.stringify(commonAreaSpaceItems[0])),
        projectId: responseData.projectId,
        spaceDefaultData: data[0],
        selectedCurrency: responseData.currency.currency,
        selectedUnit: responseData.unit.unit,
        currentUnit: responseData.unit.unit,
        clientId: responseData.client.clientId,
        projectDetails: Object.assign({}, responseData, { totalAreaRentable: originalTotalAreaRsf }),
      };
    },
    getEstimatorSpacePlanFailure(state, action) {
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    },
    getEstimatorSpacePlanCostEstimateStart(state) {
      return {
        ...state,
        loading: true,
      };
    },
    getEstimatorSpacePlanCostEstimateFailure(state, action) {
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    },
    getEstimatorSpacePlanCostEstimateSuccess(state, action) {
      return {
        ...state,
        loading: false,
        luData: [{ data: { data: action.payload } }],
        costEstimateDataLoaded: true,
        lastUpdatedDt: action.payload.spacePlanCostHdr.lastUpdatedDt,
        initialTotalCost: action.payload.spacePlanCostHdr.totalCost,
        totalCost: action.payload.spacePlanCostHdr.totalCost,
      };
    },
  },
});

export const {
  getEstimatorSpacePlanReset,
  getEstimatorSpacePlanStart,
  updateEstimatorSpacePlanDetailsState,
  getEstimatorSpacePlanSuccess,
  getEstimatorSpacePlanFailure,
  getEstimatorSpacePlanCostEstimateStart,
  getEstimatorSpacePlanCostEstimateFailure,
  getEstimatorSpacePlanCostEstimateSuccess,
} = spacePlanSlice.actions;
export default spacePlanSlice.reducer;
