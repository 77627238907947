import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  SAVE_LANGUAGE_SELECTION_START,
  SAVE_LANGUAGE_SELECTION_SUCCESS,
  SAVE_LANGUAGE_SELECTION_FAILURE,
} from 'modules/cbre-pjm-Common/constants/ActionTypes';
import apiService from 'util/apiService';

export function toggleCollapsedNav(isNavCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function setDrawerType(drawerType) {
  return { type: DRAWER_TYPE, drawerType };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeColor(color) {
  return { type: THEME_COLOR, color };
}

export function setDarkTheme() {
  return { type: DARK_THEME };
}

export function changeDirection() {
  return { type: CHANGE_DIRECTION };
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType,
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition,
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale,
  };
}

export function saveLanguageStart() {
  return { type: SAVE_LANGUAGE_SELECTION_START };
}

export function saveLanguageSuccess() {
  return { type: SAVE_LANGUAGE_SELECTION_SUCCESS };
}

export function saveLanguageFailure(error) {
  return { type: SAVE_LANGUAGE_SELECTION_FAILURE, payload: error };
}

export const updateLocaleSelection = (reqPayload) => {
  const { url, payload } = reqPayload;
  return (dispatch) => {
    dispatch(saveLanguageStart());
    return apiService
      .put(url, payload)
      .then((res) => {
        const { code, status } = res;

        if (code === 200 && status === 'Success') {
          // const { accessRequestStatus } = data;
          dispatch(saveLanguageSuccess());
        } else {
          console.error('Locale update failed');
          dispatch(saveLanguageFailure());
        }
      })
      .catch((err) => {
        dispatch(saveLanguageFailure(err));
        console.error('Locale update failed', err);
      });
  };
};
