import { SET_LOADING_TRUE, SET_LOADING_FALSE, FETCH_MY_ACTION_FAIL, FETCH_MY_ACTION_SUCCESS } from './constants';

const initialState = { loading: false, summaryData: undefined };

const widgetSummaryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING_TRUE:
      return { ...state, loading: true };
    case SET_LOADING_FALSE:
      return { ...state, loading: false };
    case FETCH_MY_ACTION_FAIL:
      return { ...state };
    case FETCH_MY_ACTION_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export default widgetSummaryReducer;
