/* eslint-disable */
import apiService from 'util/apiService';
import {
  GET_ACTIONS_PROJECTS_SUCCESS,
  GET_ACTIONS_PROJECTS_FAILURE,
  GET_ACTIONS_PROJECTS_START,
  GET_ACTIONS_PROJECT_DETAILS_START,
  GET_ACTIONS_PROJECT_DETAILS_SUCCESS,
  GET_ACTIONS_PROJECT_DETAILS_FAILURE,
  CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST,
  UPDATE_USER_ACCESS_STATUS_START,
  UPDATE_USER_ACCESS_STATUS_SUCCESS,
  UPDATE_USER_ACCESS_STATUS_FAILURE,
  UPDATE_COMMENTS_START,
  UPDATE_COMMENTS_SUCCESS,
  UPDATE_COMMENTS_FAILURE,
  IS_ACTIVE_ITEM_SET_TO_INITIAL,
} from './constants';

export const getFusionProjects = (payload) => {
  const { fusionProjectsListUrl, fusionProjectsListBaseUrl, pageIndex, isSearching, isPagination, currentErrorStatus } =
    payload;

  return (dispatch) => {
    const fusionProjectsStartObj = {};
    if (isSearching) {
      fusionProjectsStartObj.isSearching = isSearching;
    }
    if (isPagination) {
      fusionProjectsStartObj.isPagination = isPagination;
    }
    if (currentErrorStatus) {
      fusionProjectsStartObj.error = false;
    }
    dispatch(getFusionProjectsStart(fusionProjectsStartObj));
    apiService
      .get(`${fusionProjectsListUrl}&pageNumber=${pageIndex + 1}`)
      .then((res) => {
        if (res.code === 200) {
          const successObj = {
            vendorProjects: res,
            totalRecords: res.data && res.data.totalRecords ? res.data.totalRecords : 0,
            apiUrl: fusionProjectsListUrl,
            pageIndex,
          };
          if (fusionProjectsListBaseUrl) {
            successObj.apiBaseUrl = fusionProjectsListBaseUrl;
          }
          if (isSearching) {
            successObj.isSearching = false;
          }
          if (isPagination) {
            successObj.isPagination = false;
          }
          dispatch(getFusionProjectsSuccess(successObj));
          dispatch(setIsActiveItemSetToInitial(true));
        } else {
          dispatch(getFusionProjectsFailure(res.message));
          dispatch(setIsActiveItemSetToInitial(false));
        }
      })
      .catch((err) => {
        console.log('error  ---', err);
        dispatch(setIsActiveItemSetToInitial(false));
      });
  };
};

export const getFusionProjectsStart = (payload) => ({
  type: GET_ACTIONS_PROJECTS_START,
  payload,
});

export const getFusionProjectsSuccess = (payload) => ({
  type: GET_ACTIONS_PROJECTS_SUCCESS,
  payload,
});

export const setIsActiveItemSetToInitial = (payload) => ({
  type: IS_ACTIVE_ITEM_SET_TO_INITIAL,
  payload,
});

export const getFusionProjectsFailure = (payload) => ({
  type: GET_ACTIONS_PROJECTS_FAILURE,
  payload,
});

// My actions Project Details
export const getMyActionProjectDetails = (payload) => {
  return (dispatch) => {
    dispatch(getFusionProjectDetailsStart());
    apiService
      .get(`${payload.myActionProjectsDetailUrl}`)
      .then((res) => {
        const { code, messageCode, data } = res;
        if (code === 200) {
          dispatch(getFusionProjectDetailsSuccess(data));
        } else {
          dispatch(getFusionProjectDetailsFailure(messageCode));
        }
      })
      .catch((err) => {
        console.log('error  ---', err);
      });
  };
};

export const getFusionProjectDetailsStart = (payload) => ({
  type: GET_ACTIONS_PROJECT_DETAILS_START,
  payload,
});

export const getFusionProjectDetailsSuccess = (payload) => ({
  type: GET_ACTIONS_PROJECT_DETAILS_SUCCESS,
  payload,
});

export const getFusionProjectDetailsFailure = (payload) => ({
  type: GET_ACTIONS_PROJECT_DETAILS_FAILURE,
  payload,
});

// My Action Status
export const updateUserAccessStatusSuccess = (payload) => ({
  type: UPDATE_USER_ACCESS_STATUS_SUCCESS,
  payload,
});

export const updateUserAccessStatusFailure = (payload) => ({
  type: UPDATE_USER_ACCESS_STATUS_FAILURE,
  payload,
});

export const updateUserAccessStatusStart = (payload) => ({
  type: UPDATE_USER_ACCESS_STATUS_START,
  payload,
});

export const closeUpdateUserAccessStatusToast = (payload) => ({
  type: CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST,
  payload,
});

export const updateUserAccessStatus = (payload) => {
  const { comments, projectStatusUrl } = payload;
  return (dispatch) => {
    dispatch(updateUserAccessStatusStart());
    return apiService
      .put(projectStatusUrl, comments)
      .then((res) => {
        const { code, data, messageCode } = res;

        if (code === 200) {
          dispatch(updateUserAccessStatusSuccess({ data }));
        } else {
          dispatch(updateUserAccessStatusFailure(messageCode));
        }
      })
      .catch((err) => {
        dispatch(updateUserAccessStatusFailure(err.message));
      });
  };
};

// PJM Comments API
export const updateCommentsSuccess = (payload) => ({
  type: UPDATE_COMMENTS_SUCCESS,
  payload,
});

export const updateCommentsFailure = (payload) => ({
  type: UPDATE_COMMENTS_FAILURE,
  payload,
});

export const updateCommentsStart = (payload) => ({
  type: UPDATE_COMMENTS_START,
  payload,
});

export const updateComments = (payload) => {
  const { comments, commentsUrl } = payload;
  return (dispatch) => {
    dispatch(updateCommentsStart());
    return apiService
      .put(commentsUrl, comments)
      .then((res) => {
        const { code, data, messageCode } = res;

        if (code === 200) {
          dispatch(updateCommentsSuccess({ data }));
        } else {
          dispatch(updateCommentsFailure(messageCode));
        }
      })
      .catch((err) => {
        dispatch(updateCommentsFailure(err.message));
      });
  };
};
