import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_START,
  GET_MILESTONES_UNDER_PROJECT_START,
  GET_MILESTONES_UNDER_PROJECT_SUCCESS,
  GET_MILESTONES_UNDER_PROJECT_FAILURE,
  RESET_MILESTONES_UNDER_PROJECT,
  ADD_MILESTONE,
  REORDER_MILESTONES,
  DELETE_MILESTONE,
  DELETE_UNSAVED_MILESTONE,
  SEARCH_MILESTONES,
  CREATE_MILESTONES,
  UPDATE_MILESTONES,
  CREATE_MILESTONES_FAILURE,
  UPDATE_MILESTONES_FAILURE,
  SET_TOASTER_MESSAGE,
  CLEAR_TOASTER_MESSAGE,
} from './constants';
import { Milestone } from '../components/types';
import { filterMilestones, reorderMilestones } from '../utils/milestoneHelpers';

const INITIAL_STATE = {
  usersList: [],
  milestonesData: {
    milestones: [],
  },
  originalMilestones: [],
  loading: false,
  error: null,
  toasterMessage: '',
  toasterState: '',
};

export default (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_LOOKUPS_START:
      return {
        ...state,
        loading: true,
        usersList: [],
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchLookupsError: false,
        fetchLookupsStatus: 'success',
        usersList: action.payload,
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case GET_MILESTONES_UNDER_PROJECT_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_MILESTONES_UNDER_PROJECT_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          loading: false,
          milestonesData: action.payload,
          originalMilestones: action.payload?.milestones || [],
        };
      }
      return state;
    case GET_MILESTONES_UNDER_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        milestonesData: {
          milestones: [],
        },
        error: action.payload,
      };
    case CREATE_MILESTONES:
      return {
        ...state,
        milestonesData: {
          ...action.payload,
          milestones: [...action.payload.milestones],
        },
      };
    case UPDATE_MILESTONES:
      return {
        ...state,
        milestonesData: {
          ...action.payload,
          milestones: [...action.payload.milestones],
        },
      };
    case RESET_MILESTONES_UNDER_PROJECT:
      return {
        ...state,
        milestonesData: {
          ...state.milestonesData,
          milestones: state.originalMilestones,
        },
        error: null,
      };
    case ADD_MILESTONE:
      return {
        ...state,
        milestonesData: {
          ...state.milestonesData,
          milestones: state.milestonesData ? [...state.milestonesData.milestones, action.payload] : [action.payload],
        },
      };
    case DELETE_MILESTONE:
      return {
        ...state,
        milestonesData: {
          ...state.milestonesData,
          milestones: state.milestonesData?.milestones?.filter(
            (milestone: Milestone) => milestone.id !== action.payload,
          ),
        },
      };
    case DELETE_UNSAVED_MILESTONE:
      return {
        ...state,
        milestonesData: {
          ...state.milestonesData,
          milestones: state.milestonesData.milestones.filter(
            (milestone: Milestone) => !action.payload.includes(milestone.milestoneTag),
          ),
        },
      };
    case SEARCH_MILESTONES:
      return {
        ...state,
        milestonesData: {
          ...state.milestonesData,
          milestones: filterMilestones(state.originalMilestones, action?.payload?.searchTerm),
        },
      };
    case REORDER_MILESTONES:
      // eslint-disable-next-line no-case-declarations
      const { draggedMilestone, droppedMilestone } = action.payload;
      // eslint-disable-next-line no-case-declarations
      const updatedMilestones = reorderMilestones(state.milestonesData.milestones, draggedMilestone, droppedMilestone);
      return {
        ...state,
        milestonesData: {
          ...state.milestonesData,
          milestones: updatedMilestones,
        },
      };
    case SET_TOASTER_MESSAGE:
      return {
        ...state,
        toasterMessage: action.payload.message,
        toasterState: action.payload.state,
      };
    case CLEAR_TOASTER_MESSAGE:
      return {
        ...state,
        toasterMessage: '',
        toasterState: '',
      };
    case CREATE_MILESTONES_FAILURE:
    case UPDATE_MILESTONES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
