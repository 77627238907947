/* eslint-disable */
import React, { useEffect } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import { DARK_GREEN, GREEN, WHITE } from 'modules/cbre-pjm-Common/constants/ThemeColors';
import greenTheme from './themes/greenTheme';
import whiteTheme from './themes/whiteTheme';
import AppLocale from '../lngProvider';
import AppLayout from './AppLayout';

const RestrictedRoute = ({ component: Component, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      process.env.NODE_ENV == 'development' || authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const FusionApp = (props) => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, isDirectionRTL } = useSelector(({ settings }) => settings);
  const { authUser, initURL, tokenSource } = useSelector(({ auth }) => auth);
  const isDarkTheme = darkTheme;
  const { match, location } = props;

  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
  }, [dispatch, initURL, props.history.location.pathname, props.location.search]);

  const getColorTheme = (themeColor, applyTheme) => {
    switch (themeColor) {
      case GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createTheme(greenTheme);
        break;
      }
      case WHITE: {
        applyTheme = createTheme(whiteTheme);
        break;
      }
      default:
        createTheme(greenTheme);
    }
    return applyTheme;
  };

  let applyTheme = createTheme(greenTheme);
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor, applyTheme);
    document.body.classList.add(themeColor);
  }

  applyTheme.direction = 'ltr';
  const LocaleText = localStorage.getItem('locale');
  const currentAppLocale = AppLocale[LocaleText];
  return (
    <ThemeProvider theme={applyTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <IntlProvider locale={currentAppLocale?.locale} messages={currentAppLocale?.messages}>
          <div className="app-main">
            <Switch>
              <Route
                path={`${match.url}`}
                component={() => <AppLayout hideMenu={true} isFusion={tokenSource === 'fusion'} />}
              />
              {/* <Route */}
              {/*  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/> */}
            </Switch>
          </div>
        </IntlProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default FusionApp;
