import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_START,
  GET_FINANCIAL_OVERVIEW_FAILURE,
  GET_FINANCIAL_OVERVIEW_SUCCESS,
  RESET_FINANCIAL_OVERVIEW_MESSAGE,
} from './constants';

const INITIAL_STATE = {
  usersList: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
        fetchProjectDetails: true,
        usersList: action.payload,
      };

    case GET_ALL_LOOKUPS_START:
      return {
        ...state,
        loading: true,
        usersList: [],
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case GET_FINANCIAL_OVERVIEW_SUCCESS:
      if (action.payload) {
        return {
          ...state,
          financialOverview: action.payload,
        };
      }
    case GET_FINANCIAL_OVERVIEW_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case RESET_FINANCIAL_OVERVIEW_MESSAGE:
      return {
        ...state,
        error: {},
      };
    default:
      return state;
  }
};
