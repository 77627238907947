/* eslint-disable */
import {
  OPEN_SEARCH_ADD_USER_TEAM_DIALOG,
  CLOSE_SEARCH_ADD_USER_TEAM_DIALOG,
  DISABLE_SEARCH_ADD_USER_TEAM,
  RESET_SEARCH_ADD_USER_TEAM,
  START_SEARCH_ADD_USER_TEAM,
  FAILURE_SEARCH_ADD_USER_TEAM,
  SUCCESS_SEARCH_ADD_USER_TEAM,
  DUPLICATE_SEARCH_ADD_USER_TEAM,
  CLOSE_TOAST_SEARCH_ADD_USER_TEAM,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILURE,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_FAILURE,
} from './constants';

const INITIAL_STATE = {
  open: false,
  loading: false,
  error: false,
  addUserSuccess: false,
  userEditValues: [],
  messageCode: '',
  alert: '',
  alertMessage: '',
  userAlert: false,
  roles: [],
  users: [],
  isUpdate: false,
  isTeam: false,
};

export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case OPEN_SEARCH_ADD_USER_TEAM_DIALOG:
      return {
        ...state,
        open: true,
        isTeam: false,
        userEditValues: action.payload,
        obj: {},
      };
    case CLOSE_SEARCH_ADD_USER_TEAM_DIALOG:
      return {
        ...state,
        open: false,
        ...action.payload,
      };
    case DISABLE_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        open: false,
      };
    case RESET_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        error: false,
        messageCode: '',
        addUserSuccess: false,
        isTeam: false,
      };
    case START_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };

    case SUCCESS_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        ...action.payload,
      };
    case FAILURE_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,
      };
    case DUPLICATE_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,
      };
    case CLOSE_TOAST_SEARCH_ADD_USER_TEAM:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
        userAlert: false,
        isUpdate: false,
        isTeam: false,
        ...action.payload,
      };
    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case FETCH_ROLES_FAILURE:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      };
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        open: false,
        alert: 'success',
        addUserSuccess: true,
        ...action.payload,
      };
    case UPDATE_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        ...action.payload,
      };
    default:
      return state;
  }
};
