export const ESTIMATOR_GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_GET_ALL_LOOKUPS_SUCCESS';
export const ESTIMATOR_GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_GET_ALL_LOOKUPS_FAILURE';
export const ESTIMATOR_ADD_NEW_PROJECT_START = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_ADD_NEW_PROJECT_START';
export const ESTIMATOR_ADD_NEW_PROJECT_SUCCESS = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_ADD_NEW_PROJECT_SUCCESS';
export const ESTIMATOR_ADD_NEW_PROJECT_FAILURE = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_ADD_NEW_PROJECT_FAILURE';
export const ESTIMATOR_ADD_NEW_PROJECT_EXISTS_FAILURE =
  'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_ADD_NEW_PROJECT_EXISTS_FAILURE';
export const ESTIMATOR_CLOSE_NEW_PROJECT_TOAST = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_CLOSE_NEW_PROJECT_TOAST';
export const ESTIMATOR_RESET_NEW_PROJECT_STATUS = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_RESET_NEW_PROJECT_STATUS';
export const ESTIMATOR_RESET_NEW_PROJECT_ERROR = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_RESET_NEW_PROJECT_ERROR';
export const ESTIMATOR_GET_PROJECT_BY_ID_START = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_GET_PROJECT_BY_ID_START';
export const ESTIMATOR_GET_PROJECT_BY_ID_SUCCESS = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_GET_PROJECT_BY_ID_SUCCESS';
export const ESTIMATOR_SET_INITIALS = 'CBRE-PJM-ESTIMATOR-NEW-PROJECT/ESTIMATOR_SET_INITIALS';
