/* eslint-disable */
import moment from 'moment';
import {
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  GET_ALL_LOOKUPS_START,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  GET_LEGACY_COUNTRY_START,
  GET_LEGACY_COUNTRY_SUCCESS,
  GET_LEGACY_COUNTRY_FAILURE,
} from './constants';

const defaultCapitalPlanProject = {
  customerName: '',
  clientLegalEntity: '',
  enquiryForm: '',
  typeofEnquiry: '',
  creditCheckResult: '',
  winningProbability: '',
  winningProbabilityNew: '',
  contractName: '',
  rfpDueBy: null,
  estimatedProjectAwardDate: null,

  cbreEntity: '',
  managedServicesEntProjName: '',
  projectScope: '',
  projectType: '',
  projectstatus: '',
  addressLine1: '',
  addressLine2: '',
  addressCity: '',
  addressState: '',
  zipCode: '',
  addressRegion: '',
  addressCountry: '',
  division: '',
  instructionType: '',
  procurementType: '',
  operatingModel: '',
  headOfProject: '',
  projectManager: '',
  workType: '',
  estimatedStartDate: null,
  estimatedCompletionDate: null,
  erpNumber: '',
  typeOfEngagement: '',
  currency: '',
  estimatedProjectValue: '',
  contractType: '',
  assetsWorkedOn: '',
  estimatedBidMarginFee: '',
  bidMargin: '',
  pjmComments: '',

  facilityName: '',
  propertyId: '',
  facilityType: '',
  facilityManager: '',

  locationInFacility: '',
  leasedOwned: '',
  leaseRate: '',
  element: '',
  leaseEndDate: null,
  affectedArea: '',
  affectedPeopleCount: '',

  fundingSource: '',
  probabilityOfImpact: '',
  magnitudeOfImpact: '',
  organizationalImpact: '',
  strategicAlignment: '',
  facilityConditionScore: '',
  experience: '',
  timingOfImpact: '',
  projectDriver: '',
  payback: '',
  customCriteria1: '',
  customCriteria2: '',
  customCriteria3: '',
  customCriteria4: '',
  customCriteria5: '',
  priorityScore: '',
  clientPriorityScore: '',
  pjmSegmentId: '',
  divisionId: '',
};
const INITIAL_STATE = {
  allLegacy: {},
  simpleLookups: [],
  clientsList: [],
  divisionLookup: [],
  fetchLookupsError: false,
  initialClientDetails: {},
  loading: true,
  fetchLookupsStatus: '',
  createProjectStatus: '',
  createProjectError: '',
  messageCode: '',
  project: {},
  alert: '',
  isEdit: false,
  fetchProjectDetails: false,
  defaultCapitalPlanProject,
  capitalPlanProject: defaultCapitalPlanProject,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
        fetchProjectDetails: true,
      };
    case GET_ALL_LOOKUPS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case SET_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_LEGACY_COUNTRY_START:
      return {
        ...state,
        loading: true,
      };
    case GET_LEGACY_COUNTRY_SUCCESS:
      return {
        ...state,
        allLegacy: action.payload,
        loading: false,
      };
    case GET_LEGACY_COUNTRY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
