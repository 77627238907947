import { createSlice } from '@reduxjs/toolkit';

const manageUserInitialState = {
  openDialog: false,
  openAddDialog: false,
  openEditDialog: false,
  userData: [],
  isLoading: false,
  error: false,
  messageCode: '',
  message: '',
  alert: '',
  alertMessage: '',
  users: [],
  refreshData: false,
  isEdit: false,
};

export const manageUserSlice = createSlice({
  name: 'manageUser',
  initialState: manageUserInitialState,
  reducers: {
    openUserDialog: (state, { payload: userData }) => {
      state.openDialog = true;
      state.userData = userData;
      state.isEdit = !!userData;
    },
    closeUserDialog: (state) => {
      state.openDialog = false;
      state.userData = [];
      state.isEdit = false;
      state.error = false;
      state.messageCode = '';
      state.message = '';
    },
    addNewUserStart: (state) => {
      state.isLoading = true;
      state.error = false;
      state.messageCode = '';
      state.message = '';
    },
    addNewUserSuccess: (state) => {
      state.isLoading = false;
      state.openDialog = false;
      state.alert = 'success';
      state.alertMessage = 'User added successfully';
      state.refreshData = true;
    },
    addNewUserFailure: (state, { payload: { messageCode, message } }) => {
      state.isLoading = false;
      state.error = true;
      state.messageCode = messageCode;
      state.message = message;
    },
    editUserStart: (state) => {
      state.isLoading = true;
      state.error = false;
      state.messageCode = '';
      state.message = '';
    },
    editUserSuccess: (state) => {
      state.isLoading = false;
      state.openDialog = false;
      state.alert = 'success';
      state.alertMessage = 'User updated successfully';
      state.refreshData = true;
    },
    editUserFailure: (state, { payload: { messageCode, message } }) => {
      state.isLoading = false;
      state.error = true;
      state.messageCode = messageCode;
      state.message = message;
    },
    closeAlert: (state) => {
      state.alert = '';
      state.alertMessage = '';
    },
    resetManageUser: () => manageUserInitialState,
    resetRefreshData: (state) => {
      state.refreshData = false;
    },
  },
});

export const {
  addNewUserStart,
  addNewUserSuccess,
  addNewUserFailure,
  editUserStart,
  editUserSuccess,
  editUserFailure,
  openUserDialog,
  closeUserDialog,
  closeAlert,
  resetManageUser,
  resetRefreshData,
} = manageUserSlice.actions;
export default manageUserSlice.reducer;
