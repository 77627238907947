import defaultConfig from './locale/en_US';

// eslint-disable-next-line import/no-mutable-exports
const constConfig = { ...defaultConfig };

const modifyConfigData = (newConfig) => {
  Object.keys({ ...defaultConfig, ...newConfig }).forEach((key) => {
    if (newConfig[key]) constConfig[key] = newConfig[key];
  });
  return constConfig;
};

export const updateConfig = () =>
  new Promise((resolve) => {
    const locale = localStorage.getItem('locale');
    import(`./locale/${locale}`)
      .then(({ default: localeFile }) => {
        const updatedConfig = modifyConfigData(localeFile);
        resolve(updatedConfig);
      })
      .catch(() => {
        resolve(modifyConfigData({ ...defaultConfig }));
      });
  });

// update the initial config
updateConfig();

export default constConfig;

document.addEventListener('languageChange', updateConfig);
