export const OPEN_CREATE_PROGRAM_DIALOG = 'CBRE-PJM-CREATE-PROGRAM/OPEN_CREATE_PROGRAM_DIALOG';
export const CLOSE_CREATE_PROGRAM_DIALOG = 'CBRE-PJM-CREATE-PROGRAM/CLOSE_CREATE_PROGRAM_DIALOG';
export const RESET_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/RESET_CREATE_PROGRAM';

export const DISABLE_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/DISABLE_CREATE_PROGRAM';
export const START_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/START_CREATE_PROGRAM';

export const SUCCESS_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/SUCCESS_CREATE_PROGRAM';
export const FAILURE_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/FAILURE_CREATE_PROGRAM';
export const DUPLICATE_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/DUPLICATE_CREATE_PROGRAM';
export const CLOSE_TOAST_CREATE_PROGRAM = 'CBRE-PJM-CREATE-PROGRAM/CLOSE_TOAST_CREATE_PROGRAM';
export const FETCH_ROLES_SUCCESS = 'CBRE-PJM-CREATE-PROGRAM/FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAILURE = 'CBRE-PJM-CREATE-PROGRAM/FETCH_ROLES_FAILURE';
export const UPDATE_ROLE_SUCCESS = 'CBRE-PJM-CREATE-PROGRAM/UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'CBRE-PJM-CREATE-PROGRAM/UPDATE_ROLE_FAILURE';
export const GET_ALL_LOOKUPS_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_LOOKUPS_SUCCESS';
export const GET_ALL_LOOKUPS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/GET_ALL_LOOKUPS_FAILURE';
export const OPEN_EDIT_PROGRAM_DIALOG_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/OPEN_EDIT_PROGRAM_DIALOG_SUCCESS';
export const OPEN_EDIT_PROGRAM_DIALOG_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-ADDPLAN/OPEN_EDIT_PROGRAM_DIALOG_FAILURE';
