export const GET_PLAN_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_FAILURE';
export const GET_PLAN_RESET = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_RESET';
export const GET_PLAN_START = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_START';
export const GET_FILTER_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_FILTER_SUCCESS';
export const GET_FILTER_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_FILTER_FAILURE';
export const GET_FILTER_RESET = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_FILTER_RESET';
export const GET_TOP10_PROJECTS_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/ GET_TOP10_PROJECTS_SUCCESS';
export const GET_TOP10_PROJECTS_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_TOP10_PROJECTS_FAILURE';
export const GET_TOP10_PROJECTS_RESET = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_TOP10_PROJECTS_RESET';
export const SAVE_PROJECT_PRIORITY_SCORE_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/SAVE_PROJECT_PRIORITY_SCORE_FAILURE';
export const SAVE_PROJECT_PRIORITY_SCORE_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/SAVE_PROJECT_PRIORITY_SCORE_SUCCESS';
export const GET_CASH_FLOW_GRAPH_DATA_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_CASH_FLOW_GRAPH_DATA_SUCCESS';
export const GET_CASH_FLOW_GRAPH_DATA_RESET = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_CASH_FLOW_GRAPH_DATA_RESET';
export const GET_CASH_FLOW_GRAPH_DATA_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_CASH_FLOW_GRAPH_DATA_FAILURE';
export const GET_PLAN_LIST = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_LIST';
export const GET_PLAN_LIST_SUCCESS = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_LIST_SUCCESS';
export const GET_PLAN_LIST_FAILURE = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/GET_PLAN_LIST_FAILURE';
export const SET_CHECKED_PROJECT_LIST = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/SET_CHECKED_PROJECT_LIST';
export const UPDATE_CP_PROJECT_TAB_COUNT = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/UPDATE_CP_PROJECT_TAB_COUNT';
export const SAVE_PARENT_INFO = 'CBRE-PJM-CAPITAL-PLAN-DETAILS/SAVE_PARENT_INFO';
