/* eslint-disable */
import {
  GET_TAGS_DROPDOWN_FAILURE,
  GET_TAGS_DROPDOWN_START,
  GET_TAGS_DROPDOWN_SUCCESS,
  GET_TAGS_FAILURE,
  GET_TAGS_START,
  GET_TAGS_SUCCESS,
  SUBMIT_TAGS_FAILURE,
  SUBMIT_TAGS_START,
  SUBMIT_TAGS_SUCCESS,
  TAGGING_RESET,
} from './constants';

const INITIAL_STATE = {
  tags: [],
  tagOptions: [],
  errorMessage: '',
  loading: false,
  isSubmitLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TAGS_START:
      return {
        ...state,
        loading: true,
        submitSuccess: false,
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        tags: action.payload,
      };
    case GET_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case GET_TAGS_DROPDOWN_START:
      return {
        ...state,
        loading: true,
      };
    case GET_TAGS_DROPDOWN_SUCCESS:
      return {
        ...state,
        loading: false,
        tagOptions: action.payload,
      };
    case GET_TAGS_DROPDOWN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_TAGS_START:
      return {
        ...state,
        errorMessage: '',
        isSubmitLoading: true,
      };
    case SUBMIT_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        submitSuccess: true,
        isSubmitLoading: false,
      };
    case SUBMIT_TAGS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        isSubmitLoading: false,
      };
    case TAGGING_RESET:
      return {
        ...state,
        loading: false,
        isSubmitLoading: false,
        errorMessage: '',
        tags: [],
        tagOptions: [],
      };

    default:
      return state;
  }
};
