/* eslint-disable */
import { interpolate } from 'util/utilityMethods';
import constConfig from '../ducks/constants/constConfig';
import { doSpaceCalculation } from './SpacePlanCalculations';

export function createNewSpaceObject(item) {
  const obj = {};
  if (item.subSpaceId) {
    obj.subSpaceId = item.subSpaceId;
    obj.spaceSizeId = item.spaceSizeId;
  } else {
    item.subSpaceId = item.spacePlanLnId;
    obj.subSpaceId = item.subSpaceId;
    item.spaceSizeId = item.subSpaceId;
    obj.spaceSizeId = item.spaceSizeId;
  }
  obj.subSpaceName = item.subSpaceName;
  obj.selectedSpaceSizeId = '';
  obj.data = {};
  obj.data[item.spaceSizeId] = item;
  obj.subSpaceIncCircRsf = 0;
  obj.subSpaceIncLossRsf = 0;
  obj.subSpacePercentage = 0;
  return obj;
}
export function getSpaceWiseData(spaceArray) {
  const spaceObj = {
    spaceName: spaceArray[0].spaceName,
    spaceId: spaceArray[0].spaceId,
    area: {},
    totalAreaLossRsf: 0,
    totalAreaRsfPercentage: 0,
    totalAreaCircRsf: 0,
    totalAreaUsf: 0,
  };
  spaceArray.forEach((item) => {
    let hasSubSpace = [];
    if (Object.keys(spaceObj.area).length === 0) {
      const obj = createNewSpaceObject(item);
      spaceObj.area[obj.subSpaceId] = obj;
    } else {
      if (!item.subSpaceId) {
        item.subSpaceId = item.spacePlanLnId;
        item.spaceSizeId = item.subSpaceId;
      }
      hasSubSpace = spaceObj.area[item.subSpaceId];
      if (hasSubSpace) {
        spaceObj.area[item.subSpaceId].data[item.spaceSizeId] = item;
      } else {
        const obj1 = createNewSpaceObject(item);
        spaceObj.area[obj1.subSpaceId] = obj1;
      }
    }
  });
  Object.keys(spaceObj.area).forEach((item) => {
    spaceObj.area[item].selectedSpaceSizeId =
      getSelectedSpaceId(spaceObj.area[item].data) || getKeys(spaceObj.area[item].data)[0];
  });
  return spaceObj;
}
export function getSpacePlanData(spacePlanMock) {
  const mockData = spacePlanMock.spacePlanLns;
  const workSpaceArray = [];
  const collabrativSpace = [];
  const commonSpace = [];
  mockData.forEach((item) => {
    if (item.spaceName === 'Workspace') {
      workSpaceArray.push(item);
    } else if (item.spaceName === 'Collaborative Space') {
      collabrativSpace.push(item);
    } else if (item.spaceName === 'Common Areas') {
      commonSpace.push(item);
    }
  });
  return doSpaceCalculation(
    [getSpaceWiseData(workSpaceArray), getSpaceWiseData(collabrativSpace), getSpaceWiseData(commonSpace)],
    spacePlanMock,
  );
}
export function isNumeric(n) {
  return !isNaN(Number.parseFloat(n)) && isFinite(n);
}
function getQuantity(sizeItem, projectDetails) {
  return getQuantityAsPerRule(sizeItem, projectDetails);
}
function getQuantityAsPerRule(sizeItem, projectDetails) {
  const { seats, offices, workStations } = projectDetails.spacePlanHdr;
  const rule = sizeItem.spaceRule;
  switch (true) {
    case rule === 'Linked to Office Count':
      return offices;
    case rule === 'Linked to Workstation Count':
      return workStations;
    case rule === '1 per 50 Seats':
      return Math.round(seats / 50);
    case rule === '1 per 70 Seats':
      return Math.round(seats / 70);
    case rule === '1 per 150 Seats':
      return Math.round(seats / 150);
    case rule === '1 per 25 Seats':
      return Math.round(seats / 25);
    case rule === 'Default x1':
    case rule === '1 per Project':
      return seats > 0 ? 1 : 0;
    case rule === '1 per 2 Seats':
      return seats > 2 ? seats / 2 : 0;
    case rule === '1 per 100 Seats':
      return Math.round(seats / 100);
    default: {
      if (sizeItem.otherSubSpaceName) {
        return sizeItem.quantity;
      }
      return 0;
    }
  }
}
export function buildObjectForSave(originalObject) {
  const newObject = {};

  getKeys(originalObject).forEach((key) => {
    if (originalObject[key] !== null) {
      newObject[key] = originalObject[key];
    }
  });
  delete newObject.spaceName;
  delete newObject.subSpaceName;
  delete newObject.spaceSize;
  delete newObject.spaceRuleId;
  delete newObject.enabled;
  delete newObject.spaceRule;
  delete newObject.spaceRuleFactor;
  delete newObject.spacePlanHdrId;
  delete newObject.selectedSpaceSizeId;
  return newObject;
}
export function setDefaultQuantity(spaceMockData, projectDetails, id) {
  return spaceMockData.map((space) => {
    getKeys(space.area).forEach((areaIndex) => {
      getKeys(space.area[areaIndex].data).forEach((sizeId) => {
        if (id === 'officeVsWorkStationPercentage') {
          if (
            space.spaceName === 'Workspace' &&
            space.area[areaIndex].data[sizeId].subSpaceName === 'Private Offices'
          ) {
            const defaultQty = getQuantity(space.area[areaIndex].data[sizeId], projectDetails);

            let executiveOfficeProp = {};
            getKeys(space.area).forEach((areaIndexInner) => {
              if (
                space.area[areaIndex].data[space.area[areaIndexInner].selectedSpaceSizeId] &&
                space.area[areaIndex].data[space.area[areaIndexInner].selectedSpaceSizeId].subSpaceName ===
                  'Executive Office'
              ) {
                executiveOfficeProp = space.area[areaIndex].data[space.area[areaIndexInner].selectedSpaceSizeId];
              }
            });

            const execOfficeQty = Number.parseInt(executiveOfficeProp.quantity) || 0;

            if (execOfficeQty) {
              if (defaultQty >= Number.parseInt(execOfficeQty)) {
                space.area[areaIndex].data[sizeId].quantity = defaultQty - execOfficeQty;
                space.area[areaIndex].data[sizeId].totalUsable =
                  space.area[areaIndex].data[sizeId].quantity * space.area[areaIndex].data[sizeId].dimensionUsable;
              } else {
                space.area[areaIndex].data[sizeId].quantity = 0;
                space.area[areaIndex].data[sizeId].totalUsable = 0;
              }
            } else {
              space.area[areaIndex].data[sizeId].quantity = defaultQty;
              space.area[areaIndex].data[sizeId].totalUsable =
                space.area[areaIndex].data[sizeId].quantity * space.area[areaIndex].data[sizeId].dimensionUsable;
            }
          } else if (
            space.spaceName === 'Workspace' &&
            space.area[areaIndex].data[sizeId].subSpaceName === 'Executive Office'
          ) {
            space.area[areaIndex].data[sizeId].quantity =
              space.area[areaIndex].data[space.area[areaIndex].selectedSpaceSizeId].quantity;
            space.area[areaIndex].data[sizeId].totalUsable =
              space.area[areaIndex].data[sizeId].quantity * space.area[areaIndex].data[sizeId].dimensionUsable;
          } else {
            space.area[areaIndex].data[sizeId].quantity = getQuantity(
              space.area[areaIndex].data[sizeId],
              projectDetails,
            );
            space.area[areaIndex].data[sizeId].totalUsable =
              space.area[areaIndex].data[sizeId].quantity * space.area[areaIndex].data[sizeId].dimensionUsable;
          }
        } else {
          if (id === 'totalAreaRentable' && projectDetails.spacePlanHdr.totalAreaRentable > 0) {
            space.area[areaIndex].data[sizeId].quantity = getQuantity(
              space.area[areaIndex].data[sizeId],
              projectDetails,
            );
          } else if (id !== 'totalAreaRentable') {
            space.area[areaIndex].data[sizeId].quantity = getQuantity(
              space.area[areaIndex].data[sizeId],
              projectDetails,
            );
          } else {
            space.area[areaIndex].data[sizeId].quantity = 0;
          }
          space.area[areaIndex].data[sizeId].totalUsable =
            space.area[areaIndex].data[sizeId].quantity * space.area[areaIndex].data[sizeId].dimensionUsable;
        }
      });
      space.area[areaIndex].selectedSpaceSizeId =
        getSelectedSpaceId(space.area[areaIndex].data) || getKeys(space.area[areaIndex].data)[0];
    });
    return space;
  });
}
export function getSelectedSpaceId(data) {
  const selectedSpaceId = [];
  getKeys(data).forEach((size) => {
    if (data[size].quantity) {
      selectedSpaceId.push(data[size].spaceSizeId);
    }
  });
  return selectedSpaceId.length > 0 && selectedSpaceId[0];
}

export function checkForOfficeWorkStationCount(spacePlanData, offices, workStations) {
  let privateOfficeQty = 0;
  let execOfficeQty = 0;
  let workStationQty = 0;

  spacePlanData.map((space) => {
    if (space.spaceName === 'Workspace') {
      getKeys(space.area).forEach((areaIndex) => {
        getKeys(space.area[areaIndex].data).forEach((sizeId) => {
          if (space.area[areaIndex].data[sizeId].subSpaceName === 'Private Offices') {
            privateOfficeQty = space.area[areaIndex].data[space.area[areaIndex].selectedSpaceSizeId].quantity;
          } else if (space.area[areaIndex].data[sizeId].subSpaceName === 'Executive Office') {
            execOfficeQty = space.area[areaIndex].data[space.area[areaIndex].selectedSpaceSizeId].quantity;
          } else if (space.area[areaIndex].data[sizeId].subSpaceName === 'Workstations') {
            workStationQty = space.area[areaIndex].data[space.area[areaIndex].selectedSpaceSizeId].quantity;
          }
        });
      });
    }
  });

  return !(privateOfficeQty + execOfficeQty > offices || workStationQty > workStations);
}

export function getValidationErrorMessage(id) {
  let field = '';
  switch (id) {
    case 'headCount':
      field = constConfig.headCountErrorKey;
      break;
    case 'sharingRatio':
      field = constConfig.SharingRatioErrorKey;
      break;
    case 'seatDensity':
      field = constConfig.SeatDensityErrorKey;
      break;
    default:
      field = constConfig.defaultErrorKey;
      break;
  }

  return interpolate(constConfig.validationErrorMessage, { field });
  // return `Error: ${field} is a mandatory input and cannot be 0 or blank. Please enter a valid value.`;
}

export function getKeys(data) {
  return Object.keys(data);
}

export function addNewItemToSpace(spaceName, spacePlanData, item) {
  const itemArray = [];
  getKeys(item).forEach((key) => {
    if (key !== 'spaceId' && key !== 'spaceName' && key !== 'spacePlanLnId') {
      if (key === 'subSpaceId') {
        item[key] = new Date().getTime();
      } else {
        item[key] = null;
      }
    }
  });
  item.selectedSpaceSizeId = item.subSpaceId;
  item.spaceSizeId = item.subSpaceId;
  itemArray.push(item);

  const itemData = getSpaceWiseData(itemArray);

  spacePlanData.forEach((space) => {
    if (space.spaceName === spaceName) {
      space.area[item.spaceSizeId] = itemData.area[item.spaceSizeId];
    }
  });

  return spacePlanData;
}
