/* eslint-disable */
import { getMessage } from 'util/utilityMethods';
import {
  OPEN_DIALOG,
  CLOSE_DIALOG,
  ADD_CLIENT_START,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAILURE,
  ADD_CLIENT_EXISTS_FAILURE,
  CLOSE_TOAST,
  GET_ALL_LOOKUPS_SUCCESS,
  GET_ALL_LOOKUPS_FAILURE,
  RESET_CLIENT_EXISTS_FAILURE,
  LOGO_UPLOAD_ADD_CLIENT_START,
  LOGO_UPLOAD_ADD_CLIENT_SUCCESS,
  EDIT_CLIENT_START,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAILURE,
  EDIT_CLIENT_CLICK,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_START,
  UPDATE_CLIENT_FAILURE,
  RESET_CLIENT_INFO,
  RESET_CLIENT_LOGO,
} from '../constants';

const INIT_STATE = {
  open: false,
  client: {},
  clientInfo: {},
  loading: false,
  error: false,
  errorCode: '',
  alert: '',
  simpleLookups: [],
  defaultClientData: [],
  allAppsLookups: [],
  fetchLookupsStatus: '',
  isClientNameModified: false,
  alertMessage: '',
  clientId: '',
  mode: '',
  // clientId: '',
  displayForm: false,
  allLegacy: [],
  divisionLookup: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        open: true,
        mode: 'add',
        clientId: '',
        // clientInfo:{},
        clientInfo: {
          clientName: '',
          clientUniqueId: '',
          navigationUniqueCode: '',
          kahuaNumber: '',
          navigationUniqueCode: '',
          kahuaDomain: '',
          clientMatureModel: '',
          pjmSegmentId: '',
          regionId: '',
          divisionId: '',
          countryId: '',
          allApps: [], //'Capital Planner'],
          confidential: false,
          isCountryHierarchy: false,
          enableKahuaSync: false,
          isApprovalsRequired: false,
          hasSequenceApproval: false,
          isApprovalRequiredForSync: false,
          isClientDataFieldsEnabled: false,
          isFinalBudgetEnabled: false,
          isBudgetChangesEnabled: false,
          isForecastEnabled: false,
          isMonthlyForecastEnabled: false,
          isClientProjectNumberReadOnly: false,
          isClientProjectRequiredForFunding: false,
          enforceForecastAmountValidation: false,
          enableFRASubmissionWithoutForecast: false,
          enforceFRApprovalForKahuaSync: false,
          syncFromKahuaToPjmEnabled: false,
          useCostFieldFromForecast: false,
          showForecastFRAmountSummary: false,
          FRStatusViaImport: false,
        },
        alert: '',
        ...action.payload,
      };
    case EDIT_CLIENT_CLICK:
      return {
        ...state,
        ...action.payload,
      };
    case CLOSE_DIALOG:
      return {
        // open: false,
        // ...INIT_STATE, client: state.client
        ...state,
        open: false,
        mode: '',
        clientId: '',
        // clientInfo:{},
        clientInfo: {
          clientName: '',
          clientUniqueId: '',
          navigationUniqueCode: '',
          kahuaNumber: '',
          navigationUniqueCode: '',
          kahuaDomain: '',
          clientMatureModel: '',
          pjmSegmentId: '',
          regionId: '',
          divisionId: '',
          countryId: '',
          allApps: [],
          confidential: false,
          isCountryHierarchy: false,
          enableKahuaSync: false,
          isApprovalsRequired: false,
          hasSequenceApproval: false,
          isApprovalRequiredForSync: false,
          isClientDataFieldsEnabled: false,
          isFinalBudgetEnabled: false,
          isBudgetChangesEnabled: false,
          isForecastEnabled: false,
          isMonthlyForecastEnabled: false,
          isClientProjectNumberReadOnly: false,
          isClientProjectRequiredForFunding: false,
          enforceForecastAmountValidation: false,
          enforceFRApprovalForKahuaSync: false,
          syncFromKahuaToPjmEnabled: false,
        },
        alert: '',
        displayForm: false,
      };
    case ADD_CLIENT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case EDIT_CLIENT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case UPDATE_CLIENT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case EDIT_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        mode: 'edit',
        open: true,
        alertMessage: getMessage({ id: 'msg.client.updateSuccess' }),
        clientInfo: action.payload,
        clientId: action.payload.clientId,
        ...action.payload,
        displayForm: true,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.payload,
        alert: 'success',
        mode: 'edit',
        alertMessage: getMessage({ id: 'msg.client.updateSuccess' }),
        open: false,
        clientId: '',
      };
    case EDIT_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        client: action.payload,
        alert: 'success',
        displayForm: false,
        mode: 'add',
        alertMessage: getMessage({ id: 'msg.client.success' }),
        open: false,
        clientId: '',
      };
    case ADD_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case ADD_CLIENT_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case RESET_CLIENT_EXISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: false,
        errorCode: '',
      };
    case LOGO_UPLOAD_ADD_CLIENT_START:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case LOGO_UPLOAD_ADD_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case CLOSE_TOAST:
      return {
        ...state,
        alert: 'close',
        alertMessage: '',
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        fetchLookupsStatus: 'success',
      };
    case GET_ALL_LOOKUPS_FAILURE:
      return {
        ...state,
        fetchLookupsError: true,
        loading: false,
        fetchLookupsStatus: 'error',
      };
    case RESET_CLIENT_INFO:
      let { displayForm, clientName, errorDetails } = action.payload;
      return {
        ...state,
        clientInfo: {
          clientName,
          clientUniqueId: '',
          navigationUniqueCode: '',
          kahuaNumber: '',
          navigationUniqueCode: '',
          kahuaDomain: '',
          clientMatureModel: '',
          pjmSegmentId: '',
          regionId: '',
          divisionId: '',
          countryId: '',
          allApps: [],
          confidential: false,
          isCountryHierarchy: false,
          enableKahuaSync: false,
          isApprovalsRequired: false,
          hasSequenceApproval: false,
          isApprovalRequiredForSync: false,
          isClientDataFieldsEnabled: false,
          isFinalBudgetEnabled: false,
          isBudgetChangesEnabled: false,
          isForecastEnabled: false,
          isMonthlyForecastEnabled: false,
          isClientProjectNumberReadOnly: false,
          isClientProjectRequiredForFunding: false,
          enforceForecastAmountValidation: false,
          enforceFRApprovalForKahuaSync: false,
          syncFromKahuaToPjmEnabled: false,
        },
        mode: 'add',
        displayForm,
        clientId: '',
        ...errorDetails,
      };
    case RESET_CLIENT_LOGO:
      let { clientInfo } = action.payload;
      return {
        ...state,
        clientInfo: {
          ...state.clientInfo,
          ...clientInfo,
          clientLogoImage: null,
        },
      };
    default:
      return state;
  }
};
