import { getMessage } from 'util/utilityMethods';
import {
  GET_CLIENT_START,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILURE,
  GET_CLIENT_RESET,
  DELETE_USER_START,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  CLOSE_DELETE_USER_TOAST,
  UPDATE_USER_ACCESS_STATUS_START,
  UPDATE_USER_ACCESS_STATUS_SUCCESS,
  UPDATE_USER_ACCESS_STATUS_FAILURE,
  CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST,
  GET_USERS_FAILURE,
  GET_USERS_RESET,
  GET_ALL_USERS_SUCCESS,
  GET_USERS_START,
  GET_ALL_LOOKUPS_SUCCESS,
  DELETE_HIERARCHY_USER_TEAM_SUCCESS,
  DELETE_HIERARCHY_USER_FAILURE,
  DELETE_HIERARCHY_USER_START,
} from './constants';

const INITIAL_STATE = {
  alertSuccess: false,
  alertMessage: '',
  clientDetails: {},
  userDetails: {},
  showDeleteToaster: false,
  saveRenderCount: 0,
  error: false,
  errorCode: '',
  loading: true,
  deleteHierarchyloading: false,
  userAccessData: {},
  statusError: false,
  statusAlert: '',
  pageSize: 10,
  totalRecords: 0,
  pageIndex: 0,
  deletedUserCount: 0,
  simpleLookups: [],
  divisionLookup: [],
};

// eslint-disable-next-line import/no-anonymous-default-export,complexity
export default (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        clientDetails: action.payload,
        totalRecords: action.payload.totalRecords,
        pageIndex: action.payload.pageIndex,
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case GET_CLIENT_RESET:
      return {
        ...state,
        loading: false,
        clientDetails: {},
      };
    case GET_USERS_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        userDetails: action.payload,
        totalRecords: action.payload.totalRecords,
        pageIndex: action.payload.pageIndex,
      };
    case GET_USERS_RESET:
      return {
        ...state,
        loading: false,
        userDetails: {},
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorCode: action.payload,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        alertSuccess: 'success',
        alertMessage: getMessage({ id: 'msg.user.deleleSuccess' }),
        showDeleteToaster: true,
        deletedUserCount: action.payload,
      };
    case DELETE_HIERARCHY_USER_TEAM_SUCCESS:
      return {
        ...state,
        deleteHierarchyloading: false,
        alertSuccess: 'success',
        alertMessage: action.payload,
        showDeleteToaster: true,
      };
    case DELETE_HIERARCHY_USER_FAILURE:
      return {
        ...state,
        alertSuccess: 'failure',
        deleteHierarchyloading: false,
        error: true,
        alertMessage: action.payload,
      };
    case DELETE_HIERARCHY_USER_START:
      return {
        ...state,
        deleteHierarchyloading: true,
        ...action.payload,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        alertSuccess: 'failure',
        deleteHierarchyloading: false,
        error: true,
      };
    case DELETE_USER_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case CLOSE_DELETE_USER_TOAST:
      return {
        ...state,
        alertSuccess: 'close',
        alertMessage: '',
        deleteDialogOpen: false,
      };
    case UPDATE_USER_ACCESS_STATUS_START:
      return {
        ...state,
        loading: true,
        ...action.payload,
      };
    case UPDATE_USER_ACCESS_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        statusAlert: 'success',
        userAccessData: action.payload,
        saveRenderCount: state.saveRenderCount + 1,
      };
    case UPDATE_USER_ACCESS_STATUS_FAILURE:
      return {
        ...state,
        statusError: true,
        statusAlert: 'failure',
      };
    case CLOSE_UPDATE_USER_ACCESS_STATUS_TOAST:
      return {
        ...state,
        statusAlert: 'close',
      };
    case GET_ALL_LOOKUPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
